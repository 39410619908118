import { __assign, __awaiter, __generator, __read } from "tslib";
import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectLong as __expectLong, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectString as __expectString, expectUnion as __expectUnion, limitedParseDouble as __limitedParseDouble, parseEpochTimestamp as __parseEpochTimestamp, serializeFloat as __serializeFloat, } from "@aws-sdk/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { DynamoDBServiceException as __BaseException } from "../models/DynamoDBServiceException";
import { AttributeValue, BackupInUseException, BackupNotFoundException, ConditionalCheckFailedException, ContinuousBackupsUnavailableException, DuplicateItemException, ExportConflictException, ExportNotFoundException, GlobalTableAlreadyExistsException, GlobalTableNotFoundException, IdempotentParameterMismatchException, IndexNotFoundException, InternalServerError, InvalidEndpointException, InvalidExportTimeException, InvalidRestoreTimeException, ItemCollectionSizeLimitExceededException, LimitExceededException, PointInTimeRecoveryUnavailableException, ProvisionedThroughputExceededException, ReplicaAlreadyExistsException, ReplicaNotFoundException, RequestLimitExceeded, ResourceInUseException, ResourceNotFoundException, TableAlreadyExistsException, TableInUseException, TableNotFoundException, TransactionCanceledException, TransactionConflictException, TransactionInProgressException, } from "../models/models_0";
export var serializeAws_json1_0BatchExecuteStatementCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.BatchExecuteStatement",
        };
        body = JSON.stringify(serializeAws_json1_0BatchExecuteStatementInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0BatchGetItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.BatchGetItem",
        };
        body = JSON.stringify(serializeAws_json1_0BatchGetItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0BatchWriteItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.BatchWriteItem",
        };
        body = JSON.stringify(serializeAws_json1_0BatchWriteItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0CreateBackupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.CreateBackup",
        };
        body = JSON.stringify(serializeAws_json1_0CreateBackupInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0CreateGlobalTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.CreateGlobalTable",
        };
        body = JSON.stringify(serializeAws_json1_0CreateGlobalTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0CreateTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.CreateTable",
        };
        body = JSON.stringify(serializeAws_json1_0CreateTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DeleteBackupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DeleteBackup",
        };
        body = JSON.stringify(serializeAws_json1_0DeleteBackupInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DeleteItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DeleteItem",
        };
        body = JSON.stringify(serializeAws_json1_0DeleteItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DeleteTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DeleteTable",
        };
        body = JSON.stringify(serializeAws_json1_0DeleteTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeBackupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeBackup",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeBackupInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeContinuousBackupsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeContinuousBackups",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeContinuousBackupsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeContributorInsightsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeContributorInsights",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeContributorInsightsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeEndpointsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeEndpoints",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeEndpointsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeExportCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeExport",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeExportInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeGlobalTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeGlobalTable",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeGlobalTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeGlobalTableSettingsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeGlobalTableSettings",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeGlobalTableSettingsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeKinesisStreamingDestinationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeKinesisStreamingDestination",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeKinesisStreamingDestinationInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeLimitsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeLimits",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeLimitsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeTable",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeTableReplicaAutoScalingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeTableReplicaAutoScaling",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeTableReplicaAutoScalingInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DescribeTimeToLiveCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DescribeTimeToLive",
        };
        body = JSON.stringify(serializeAws_json1_0DescribeTimeToLiveInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0DisableKinesisStreamingDestinationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.DisableKinesisStreamingDestination",
        };
        body = JSON.stringify(serializeAws_json1_0KinesisStreamingDestinationInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0EnableKinesisStreamingDestinationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.EnableKinesisStreamingDestination",
        };
        body = JSON.stringify(serializeAws_json1_0KinesisStreamingDestinationInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ExecuteStatementCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ExecuteStatement",
        };
        body = JSON.stringify(serializeAws_json1_0ExecuteStatementInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ExecuteTransactionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ExecuteTransaction",
        };
        body = JSON.stringify(serializeAws_json1_0ExecuteTransactionInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ExportTableToPointInTimeCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ExportTableToPointInTime",
        };
        body = JSON.stringify(serializeAws_json1_0ExportTableToPointInTimeInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0GetItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.GetItem",
        };
        body = JSON.stringify(serializeAws_json1_0GetItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListBackupsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListBackups",
        };
        body = JSON.stringify(serializeAws_json1_0ListBackupsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListContributorInsightsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListContributorInsights",
        };
        body = JSON.stringify(serializeAws_json1_0ListContributorInsightsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListExportsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListExports",
        };
        body = JSON.stringify(serializeAws_json1_0ListExportsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListGlobalTablesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListGlobalTables",
        };
        body = JSON.stringify(serializeAws_json1_0ListGlobalTablesInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListTablesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListTables",
        };
        body = JSON.stringify(serializeAws_json1_0ListTablesInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ListTagsOfResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.ListTagsOfResource",
        };
        body = JSON.stringify(serializeAws_json1_0ListTagsOfResourceInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0PutItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.PutItem",
        };
        body = JSON.stringify(serializeAws_json1_0PutItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0QueryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.Query",
        };
        body = JSON.stringify(serializeAws_json1_0QueryInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0RestoreTableFromBackupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.RestoreTableFromBackup",
        };
        body = JSON.stringify(serializeAws_json1_0RestoreTableFromBackupInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0RestoreTableToPointInTimeCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.RestoreTableToPointInTime",
        };
        body = JSON.stringify(serializeAws_json1_0RestoreTableToPointInTimeInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0ScanCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.Scan",
        };
        body = JSON.stringify(serializeAws_json1_0ScanInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0TagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.TagResource",
        };
        body = JSON.stringify(serializeAws_json1_0TagResourceInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0TransactGetItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.TransactGetItems",
        };
        body = JSON.stringify(serializeAws_json1_0TransactGetItemsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0TransactWriteItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.TransactWriteItems",
        };
        body = JSON.stringify(serializeAws_json1_0TransactWriteItemsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UntagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UntagResource",
        };
        body = JSON.stringify(serializeAws_json1_0UntagResourceInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateContinuousBackupsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateContinuousBackups",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateContinuousBackupsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateContributorInsightsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateContributorInsights",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateContributorInsightsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateGlobalTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateGlobalTable",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateGlobalTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateGlobalTableSettingsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateGlobalTableSettings",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateGlobalTableSettingsInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateItem",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateItemInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateTableCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateTable",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateTableInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateTableReplicaAutoScalingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateTableReplicaAutoScaling",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateTableReplicaAutoScalingInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_0UpdateTimeToLiveCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.0",
            "x-amz-target": "DynamoDB_20120810.UpdateTimeToLive",
        };
        body = JSON.stringify(serializeAws_json1_0UpdateTimeToLiveInput(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var deserializeAws_json1_0BatchExecuteStatementCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0BatchExecuteStatementCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0BatchExecuteStatementOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0BatchExecuteStatementCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "RequestLimitExceeded": return [3, 4];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0BatchGetItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0BatchGetItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0BatchGetItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0BatchGetItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0BatchWriteItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0BatchWriteItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0BatchWriteItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0BatchWriteItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ItemCollectionSizeLimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ItemCollectionSizeLimitExceededException": return [3, 6];
                    case "ProvisionedThroughputExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 8];
                    case "RequestLimitExceeded": return [3, 10];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 10];
                    case "ResourceNotFoundException": return [3, 12];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0CreateBackupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0CreateBackupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0CreateBackupOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0CreateBackupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BackupInUseException": return [3, 2];
                    case "com.amazonaws.dynamodb#BackupInUseException": return [3, 2];
                    case "ContinuousBackupsUnavailableException": return [3, 4];
                    case "com.amazonaws.dynamodb#ContinuousBackupsUnavailableException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 6];
                    case "InvalidEndpointException": return [3, 8];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 8];
                    case "LimitExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 10];
                    case "TableInUseException": return [3, 12];
                    case "com.amazonaws.dynamodb#TableInUseException": return [3, 12];
                    case "TableNotFoundException": return [3, 14];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_json1_0BackupInUseExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0ContinuousBackupsUnavailableExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TableInUseExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0CreateGlobalTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0CreateGlobalTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0CreateGlobalTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0CreateGlobalTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "GlobalTableAlreadyExistsException": return [3, 2];
                    case "com.amazonaws.dynamodb#GlobalTableAlreadyExistsException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "LimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 8];
                    case "TableNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0GlobalTableAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0CreateTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0CreateTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0CreateTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0CreateTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DeleteBackupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DeleteBackupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DeleteBackupOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DeleteBackupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BackupInUseException": return [3, 2];
                    case "com.amazonaws.dynamodb#BackupInUseException": return [3, 2];
                    case "BackupNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#BackupNotFoundException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 6];
                    case "InvalidEndpointException": return [3, 8];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 8];
                    case "LimitExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0BackupInUseExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0BackupNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DeleteItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DeleteItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DeleteItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DeleteItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ConditionalCheckFailedException": return [3, 2];
                    case "com.amazonaws.dynamodb#ConditionalCheckFailedException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "ProvisionedThroughputExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 10];
                    case "RequestLimitExceeded": return [3, 12];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 12];
                    case "ResourceNotFoundException": return [3, 14];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 14];
                    case "TransactionConflictException": return [3, 16];
                    case "com.amazonaws.dynamodb#TransactionConflictException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0ConditionalCheckFailedExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TransactionConflictExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DeleteTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DeleteTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DeleteTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DeleteTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeBackupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeBackupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeBackupOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeBackupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BackupNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#BackupNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0BackupNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeContinuousBackupsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeContinuousBackupsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeContinuousBackupsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeContinuousBackupsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "TableNotFoundException": return [3, 6];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeContributorInsightsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeContributorInsightsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeContributorInsightsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeContributorInsightsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "ResourceNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeEndpointsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeEndpointsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeEndpointsResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeEndpointsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, parsedBody, $metadata, statusCode;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                switch (errorCode) {
                    default:
                        parsedBody = parsedOutput.body;
                        $metadata = deserializeMetadata(output);
                        statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                        response = new __BaseException({
                            name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                            $fault: "client",
                            $metadata: $metadata,
                        });
                        throw __decorateServiceException(response, parsedBody);
                }
                return [2];
        }
    });
}); };
export var deserializeAws_json1_0DescribeExportCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeExportCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeExportOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeExportCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ExportNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#ExportNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0ExportNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeGlobalTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeGlobalTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeGlobalTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeGlobalTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "GlobalTableNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#GlobalTableNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0GlobalTableNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeGlobalTableSettingsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeGlobalTableSettingsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeGlobalTableSettingsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeGlobalTableSettingsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "GlobalTableNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#GlobalTableNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0GlobalTableNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeKinesisStreamingDestinationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeKinesisStreamingDestinationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeKinesisStreamingDestinationOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeKinesisStreamingDestinationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeLimitsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeLimitsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeLimitsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeLimitsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeTableReplicaAutoScalingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeTableReplicaAutoScalingCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeTableReplicaAutoScalingOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeTableReplicaAutoScalingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "ResourceNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DescribeTimeToLiveCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DescribeTimeToLiveCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0DescribeTimeToLiveOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DescribeTimeToLiveCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0DisableKinesisStreamingDestinationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0DisableKinesisStreamingDestinationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0KinesisStreamingDestinationOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0DisableKinesisStreamingDestinationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0EnableKinesisStreamingDestinationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0EnableKinesisStreamingDestinationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0KinesisStreamingDestinationOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0EnableKinesisStreamingDestinationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ExecuteStatementCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ExecuteStatementCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ExecuteStatementOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ExecuteStatementCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ConditionalCheckFailedException": return [3, 2];
                    case "com.amazonaws.dynamodb#ConditionalCheckFailedException": return [3, 2];
                    case "DuplicateItemException": return [3, 4];
                    case "com.amazonaws.dynamodb#DuplicateItemException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 6];
                    case "ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "ProvisionedThroughputExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 10];
                    case "RequestLimitExceeded": return [3, 12];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 12];
                    case "ResourceNotFoundException": return [3, 14];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 14];
                    case "TransactionConflictException": return [3, 16];
                    case "com.amazonaws.dynamodb#TransactionConflictException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0ConditionalCheckFailedExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0DuplicateItemExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TransactionConflictExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ExecuteTransactionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ExecuteTransactionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ExecuteTransactionOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ExecuteTransactionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "IdempotentParameterMismatchException": return [3, 2];
                    case "com.amazonaws.dynamodb#IdempotentParameterMismatchException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                    case "TransactionCanceledException": return [3, 12];
                    case "com.amazonaws.dynamodb#TransactionCanceledException": return [3, 12];
                    case "TransactionInProgressException": return [3, 14];
                    case "com.amazonaws.dynamodb#TransactionInProgressException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_json1_0IdempotentParameterMismatchExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TransactionCanceledExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0TransactionInProgressExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ExportTableToPointInTimeCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ExportTableToPointInTimeCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ExportTableToPointInTimeOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ExportTableToPointInTimeCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ExportConflictException": return [3, 2];
                    case "com.amazonaws.dynamodb#ExportConflictException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidExportTimeException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidExportTimeException": return [3, 6];
                    case "LimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 8];
                    case "PointInTimeRecoveryUnavailableException": return [3, 10];
                    case "com.amazonaws.dynamodb#PointInTimeRecoveryUnavailableException": return [3, 10];
                    case "TableNotFoundException": return [3, 12];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_json1_0ExportConflictExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidExportTimeExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0PointInTimeRecoveryUnavailableExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0GetItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0GetItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0GetItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0GetItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListBackupsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListBackupsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListBackupsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListBackupsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListContributorInsightsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListContributorInsightsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListContributorInsightsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListContributorInsightsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "ResourceNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListExportsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListExportsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListExportsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListExportsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "LimitExceededException": return [3, 4];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListGlobalTablesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListGlobalTablesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListGlobalTablesOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListGlobalTablesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListTablesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListTablesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListTablesOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListTablesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ListTagsOfResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ListTagsOfResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ListTagsOfResourceOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ListTagsOfResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0PutItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0PutItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0PutItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0PutItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ConditionalCheckFailedException": return [3, 2];
                    case "com.amazonaws.dynamodb#ConditionalCheckFailedException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "ProvisionedThroughputExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 10];
                    case "RequestLimitExceeded": return [3, 12];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 12];
                    case "ResourceNotFoundException": return [3, 14];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 14];
                    case "TransactionConflictException": return [3, 16];
                    case "com.amazonaws.dynamodb#TransactionConflictException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0ConditionalCheckFailedExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TransactionConflictExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0QueryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0QueryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0QueryOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0QueryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0RestoreTableFromBackupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0RestoreTableFromBackupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0RestoreTableFromBackupOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0RestoreTableFromBackupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BackupInUseException": return [3, 2];
                    case "com.amazonaws.dynamodb#BackupInUseException": return [3, 2];
                    case "BackupNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#BackupNotFoundException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 6];
                    case "InvalidEndpointException": return [3, 8];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 8];
                    case "LimitExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 10];
                    case "TableAlreadyExistsException": return [3, 12];
                    case "com.amazonaws.dynamodb#TableAlreadyExistsException": return [3, 12];
                    case "TableInUseException": return [3, 14];
                    case "com.amazonaws.dynamodb#TableInUseException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_json1_0BackupInUseExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0BackupNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TableAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0TableInUseExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0RestoreTableToPointInTimeCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0RestoreTableToPointInTimeCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0RestoreTableToPointInTimeOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0RestoreTableToPointInTimeCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "InvalidRestoreTimeException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidRestoreTimeException": return [3, 6];
                    case "LimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 8];
                    case "PointInTimeRecoveryUnavailableException": return [3, 10];
                    case "com.amazonaws.dynamodb#PointInTimeRecoveryUnavailableException": return [3, 10];
                    case "TableAlreadyExistsException": return [3, 12];
                    case "com.amazonaws.dynamodb#TableAlreadyExistsException": return [3, 12];
                    case "TableInUseException": return [3, 14];
                    case "com.amazonaws.dynamodb#TableInUseException": return [3, 14];
                    case "TableNotFoundException": return [3, 16];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidRestoreTimeExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0PointInTimeRecoveryUnavailableExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TableAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0TableInUseExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0ScanCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0ScanCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0ScanOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0ScanCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0TagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0TagResourceCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0TagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0TransactGetItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0TransactGetItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0TransactGetItemsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0TransactGetItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "ProvisionedThroughputExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 6];
                    case "RequestLimitExceeded": return [3, 8];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                    case "TransactionCanceledException": return [3, 12];
                    case "com.amazonaws.dynamodb#TransactionCanceledException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TransactionCanceledExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0TransactWriteItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0TransactWriteItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0TransactWriteItemsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0TransactWriteItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "IdempotentParameterMismatchException": return [3, 2];
                    case "com.amazonaws.dynamodb#IdempotentParameterMismatchException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "ProvisionedThroughputExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 8];
                    case "RequestLimitExceeded": return [3, 10];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 10];
                    case "ResourceNotFoundException": return [3, 12];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 12];
                    case "TransactionCanceledException": return [3, 14];
                    case "com.amazonaws.dynamodb#TransactionCanceledException": return [3, 14];
                    case "TransactionInProgressException": return [3, 16];
                    case "com.amazonaws.dynamodb#TransactionInProgressException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0IdempotentParameterMismatchExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0TransactionCanceledExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TransactionInProgressExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UntagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UntagResourceCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UntagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateContinuousBackupsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateContinuousBackupsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateContinuousBackupsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateContinuousBackupsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ContinuousBackupsUnavailableException": return [3, 2];
                    case "com.amazonaws.dynamodb#ContinuousBackupsUnavailableException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "TableNotFoundException": return [3, 8];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_json1_0ContinuousBackupsUnavailableExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateContributorInsightsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateContributorInsightsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateContributorInsightsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateContributorInsightsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "ResourceNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateGlobalTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateGlobalTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateGlobalTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateGlobalTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "GlobalTableNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#GlobalTableNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "ReplicaAlreadyExistsException": return [3, 8];
                    case "com.amazonaws.dynamodb#ReplicaAlreadyExistsException": return [3, 8];
                    case "ReplicaNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ReplicaNotFoundException": return [3, 10];
                    case "TableNotFoundException": return [3, 12];
                    case "com.amazonaws.dynamodb#TableNotFoundException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_json1_0GlobalTableNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ReplicaAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ReplicaNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0TableNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateGlobalTableSettingsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateGlobalTableSettingsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateGlobalTableSettingsOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateGlobalTableSettingsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "GlobalTableNotFoundException": return [3, 2];
                    case "com.amazonaws.dynamodb#GlobalTableNotFoundException": return [3, 2];
                    case "IndexNotFoundException": return [3, 4];
                    case "com.amazonaws.dynamodb#IndexNotFoundException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 6];
                    case "InvalidEndpointException": return [3, 8];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 8];
                    case "LimitExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 10];
                    case "ReplicaNotFoundException": return [3, 12];
                    case "com.amazonaws.dynamodb#ReplicaNotFoundException": return [3, 12];
                    case "ResourceInUseException": return [3, 14];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_json1_0GlobalTableNotFoundExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0IndexNotFoundExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0ReplicaNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateItemOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ConditionalCheckFailedException": return [3, 2];
                    case "com.amazonaws.dynamodb#ConditionalCheckFailedException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 4];
                    case "InvalidEndpointException": return [3, 6];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 6];
                    case "ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "com.amazonaws.dynamodb#ItemCollectionSizeLimitExceededException": return [3, 8];
                    case "ProvisionedThroughputExceededException": return [3, 10];
                    case "com.amazonaws.dynamodb#ProvisionedThroughputExceededException": return [3, 10];
                    case "RequestLimitExceeded": return [3, 12];
                    case "com.amazonaws.dynamodb#RequestLimitExceeded": return [3, 12];
                    case "ResourceNotFoundException": return [3, 14];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 14];
                    case "TransactionConflictException": return [3, 16];
                    case "com.amazonaws.dynamodb#TransactionConflictException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_json1_0ConditionalCheckFailedExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_json1_0RequestLimitExceededResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_json1_0TransactionConflictExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateTableCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateTableCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateTableOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateTableCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateTableReplicaAutoScalingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateTableReplicaAutoScalingCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateTableReplicaAutoScalingOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateTableReplicaAutoScalingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "LimitExceededException": return [3, 4];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 4];
                    case "ResourceInUseException": return [3, 6];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
export var deserializeAws_json1_0UpdateTimeToLiveCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_0UpdateTimeToLiveCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_0UpdateTimeToLiveOutput(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_0UpdateTimeToLiveCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody, $metadata, statusCode;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.dynamodb#InternalServerError": return [3, 2];
                    case "InvalidEndpointException": return [3, 4];
                    case "com.amazonaws.dynamodb#InvalidEndpointException": return [3, 4];
                    case "LimitExceededException": return [3, 6];
                    case "com.amazonaws.dynamodb#LimitExceededException": return [3, 6];
                    case "ResourceInUseException": return [3, 8];
                    case "com.amazonaws.dynamodb#ResourceInUseException": return [3, 8];
                    case "ResourceNotFoundException": return [3, 10];
                    case "com.amazonaws.dynamodb#ResourceNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_json1_0InternalServerErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_json1_0InvalidEndpointExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_json1_0LimitExceededExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_json1_0ResourceInUseExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_json1_0ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                $metadata = deserializeMetadata(output);
                statusCode = $metadata.httpStatusCode ? $metadata.httpStatusCode + "" : undefined;
                response = new __BaseException({
                    name: parsedBody.code || parsedBody.Code || errorCode || statusCode || "UnknowError",
                    $fault: "client",
                    $metadata: $metadata,
                });
                throw __decorateServiceException(response, parsedBody);
        }
    });
}); };
var deserializeAws_json1_0BackupInUseExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0BackupInUseException(body, context);
        exception = new BackupInUseException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0BackupNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0BackupNotFoundException(body, context);
        exception = new BackupNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ConditionalCheckFailedExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ConditionalCheckFailedException(body, context);
        exception = new ConditionalCheckFailedException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ContinuousBackupsUnavailableExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ContinuousBackupsUnavailableException(body, context);
        exception = new ContinuousBackupsUnavailableException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0DuplicateItemExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0DuplicateItemException(body, context);
        exception = new DuplicateItemException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ExportConflictExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ExportConflictException(body, context);
        exception = new ExportConflictException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ExportNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ExportNotFoundException(body, context);
        exception = new ExportNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0GlobalTableAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0GlobalTableAlreadyExistsException(body, context);
        exception = new GlobalTableAlreadyExistsException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0GlobalTableNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0GlobalTableNotFoundException(body, context);
        exception = new GlobalTableNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0IdempotentParameterMismatchExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0IdempotentParameterMismatchException(body, context);
        exception = new IdempotentParameterMismatchException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0IndexNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0IndexNotFoundException(body, context);
        exception = new IndexNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0InternalServerErrorResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0InternalServerError(body, context);
        exception = new InternalServerError(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0InvalidEndpointExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0InvalidEndpointException(body, context);
        exception = new InvalidEndpointException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0InvalidExportTimeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0InvalidExportTimeException(body, context);
        exception = new InvalidExportTimeException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0InvalidRestoreTimeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0InvalidRestoreTimeException(body, context);
        exception = new InvalidRestoreTimeException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ItemCollectionSizeLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ItemCollectionSizeLimitExceededException(body, context);
        exception = new ItemCollectionSizeLimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0LimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0LimitExceededException(body, context);
        exception = new LimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0PointInTimeRecoveryUnavailableExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0PointInTimeRecoveryUnavailableException(body, context);
        exception = new PointInTimeRecoveryUnavailableException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ProvisionedThroughputExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ProvisionedThroughputExceededException(body, context);
        exception = new ProvisionedThroughputExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ReplicaAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ReplicaAlreadyExistsException(body, context);
        exception = new ReplicaAlreadyExistsException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ReplicaNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ReplicaNotFoundException(body, context);
        exception = new ReplicaNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0RequestLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0RequestLimitExceeded(body, context);
        exception = new RequestLimitExceeded(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ResourceInUseExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ResourceInUseException(body, context);
        exception = new ResourceInUseException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0ResourceNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0ResourceNotFoundException(body, context);
        exception = new ResourceNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TableAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TableAlreadyExistsException(body, context);
        exception = new TableAlreadyExistsException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TableInUseExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TableInUseException(body, context);
        exception = new TableInUseException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TableNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TableNotFoundException(body, context);
        exception = new TableNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TransactionCanceledExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TransactionCanceledException(body, context);
        exception = new TransactionCanceledException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TransactionConflictExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TransactionConflictException(body, context);
        exception = new TransactionConflictException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_json1_0TransactionInProgressExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_0TransactionInProgressException(body, context);
        exception = new TransactionInProgressException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var serializeAws_json1_0AttributeDefinition = function (input, context) {
    return __assign(__assign({}, (input.AttributeName !== undefined && input.AttributeName !== null && { AttributeName: input.AttributeName })), (input.AttributeType !== undefined && input.AttributeType !== null && { AttributeType: input.AttributeType }));
};
var serializeAws_json1_0AttributeDefinitions = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0AttributeDefinition(entry, context);
    });
};
var serializeAws_json1_0AttributeNameList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_0AttributeUpdates = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0AttributeValueUpdate(value, context), _b));
    }, {});
};
var serializeAws_json1_0AttributeValue = function (input, context) {
    return AttributeValue.visit(input, {
        B: function (value) { return ({ B: context.base64Encoder(value) }); },
        BOOL: function (value) { return ({ BOOL: value }); },
        BS: function (value) { return ({ BS: serializeAws_json1_0BinarySetAttributeValue(value, context) }); },
        L: function (value) { return ({ L: serializeAws_json1_0ListAttributeValue(value, context) }); },
        M: function (value) { return ({ M: serializeAws_json1_0MapAttributeValue(value, context) }); },
        N: function (value) { return ({ N: value }); },
        NS: function (value) { return ({ NS: serializeAws_json1_0NumberSetAttributeValue(value, context) }); },
        NULL: function (value) { return ({ NULL: value }); },
        S: function (value) { return ({ S: value }); },
        SS: function (value) { return ({ SS: serializeAws_json1_0StringSetAttributeValue(value, context) }); },
        _: function (name, value) { return ({ name: value }); },
    });
};
var serializeAws_json1_0AttributeValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0AttributeValue(entry, context);
    });
};
var serializeAws_json1_0AttributeValueUpdate = function (input, context) {
    return __assign(__assign({}, (input.Action !== undefined && input.Action !== null && { Action: input.Action })), (input.Value !== undefined &&
        input.Value !== null && { Value: serializeAws_json1_0AttributeValue(input.Value, context) }));
};
var serializeAws_json1_0AutoScalingPolicyUpdate = function (input, context) {
    return __assign(__assign({}, (input.PolicyName !== undefined && input.PolicyName !== null && { PolicyName: input.PolicyName })), (input.TargetTrackingScalingPolicyConfiguration !== undefined &&
        input.TargetTrackingScalingPolicyConfiguration !== null && {
        TargetTrackingScalingPolicyConfiguration: serializeAws_json1_0AutoScalingTargetTrackingScalingPolicyConfigurationUpdate(input.TargetTrackingScalingPolicyConfiguration, context),
    }));
};
var serializeAws_json1_0AutoScalingSettingsUpdate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AutoScalingDisabled !== undefined &&
        input.AutoScalingDisabled !== null && { AutoScalingDisabled: input.AutoScalingDisabled })), (input.AutoScalingRoleArn !== undefined &&
        input.AutoScalingRoleArn !== null && { AutoScalingRoleArn: input.AutoScalingRoleArn })), (input.MaximumUnits !== undefined && input.MaximumUnits !== null && { MaximumUnits: input.MaximumUnits })), (input.MinimumUnits !== undefined && input.MinimumUnits !== null && { MinimumUnits: input.MinimumUnits })), (input.ScalingPolicyUpdate !== undefined &&
        input.ScalingPolicyUpdate !== null && {
        ScalingPolicyUpdate: serializeAws_json1_0AutoScalingPolicyUpdate(input.ScalingPolicyUpdate, context),
    }));
};
var serializeAws_json1_0AutoScalingTargetTrackingScalingPolicyConfigurationUpdate = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DisableScaleIn !== undefined &&
        input.DisableScaleIn !== null && { DisableScaleIn: input.DisableScaleIn })), (input.ScaleInCooldown !== undefined &&
        input.ScaleInCooldown !== null && { ScaleInCooldown: input.ScaleInCooldown })), (input.ScaleOutCooldown !== undefined &&
        input.ScaleOutCooldown !== null && { ScaleOutCooldown: input.ScaleOutCooldown })), (input.TargetValue !== undefined &&
        input.TargetValue !== null && { TargetValue: __serializeFloat(input.TargetValue) }));
};
var serializeAws_json1_0BatchExecuteStatementInput = function (input, context) {
    return __assign(__assign({}, (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.Statements !== undefined &&
        input.Statements !== null && { Statements: serializeAws_json1_0PartiQLBatchRequest(input.Statements, context) }));
};
var serializeAws_json1_0BatchGetItemInput = function (input, context) {
    return __assign(__assign({}, (input.RequestItems !== undefined &&
        input.RequestItems !== null && {
        RequestItems: serializeAws_json1_0BatchGetRequestMap(input.RequestItems, context),
    })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity }));
};
var serializeAws_json1_0BatchGetRequestMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0KeysAndAttributes(value, context), _b));
    }, {});
};
var serializeAws_json1_0BatchStatementRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_0PreparedStatementParameters(input.Parameters, context),
    })), (input.Statement !== undefined && input.Statement !== null && { Statement: input.Statement }));
};
var serializeAws_json1_0BatchWriteItemInput = function (input, context) {
    return __assign(__assign(__assign({}, (input.RequestItems !== undefined &&
        input.RequestItems !== null && {
        RequestItems: serializeAws_json1_0BatchWriteItemRequestMap(input.RequestItems, context),
    })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ReturnItemCollectionMetrics !== undefined &&
        input.ReturnItemCollectionMetrics !== null && { ReturnItemCollectionMetrics: input.ReturnItemCollectionMetrics }));
};
var serializeAws_json1_0BatchWriteItemRequestMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0WriteRequests(value, context), _b));
    }, {});
};
var serializeAws_json1_0BinarySetAttributeValue = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return context.base64Encoder(entry);
    });
};
var serializeAws_json1_0Condition = function (input, context) {
    return __assign(__assign({}, (input.AttributeValueList !== undefined &&
        input.AttributeValueList !== null && {
        AttributeValueList: serializeAws_json1_0AttributeValueList(input.AttributeValueList, context),
    })), (input.ComparisonOperator !== undefined &&
        input.ComparisonOperator !== null && { ComparisonOperator: input.ComparisonOperator }));
};
var serializeAws_json1_0ConditionCheck = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ReturnValuesOnConditionCheckFailure !== undefined &&
        input.ReturnValuesOnConditionCheckFailure !== null && {
        ReturnValuesOnConditionCheckFailure: input.ReturnValuesOnConditionCheckFailure,
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0CreateBackupInput = function (input, context) {
    return __assign(__assign({}, (input.BackupName !== undefined && input.BackupName !== null && { BackupName: input.BackupName })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0CreateGlobalSecondaryIndexAction = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.KeySchema !== undefined &&
        input.KeySchema !== null && { KeySchema: serializeAws_json1_0KeySchema(input.KeySchema, context) })), (input.Projection !== undefined &&
        input.Projection !== null && { Projection: serializeAws_json1_0Projection(input.Projection, context) })), (input.ProvisionedThroughput !== undefined &&
        input.ProvisionedThroughput !== null && {
        ProvisionedThroughput: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughput, context),
    }));
};
var serializeAws_json1_0CreateGlobalTableInput = function (input, context) {
    return __assign(__assign({}, (input.GlobalTableName !== undefined &&
        input.GlobalTableName !== null && { GlobalTableName: input.GlobalTableName })), (input.ReplicationGroup !== undefined &&
        input.ReplicationGroup !== null && {
        ReplicationGroup: serializeAws_json1_0ReplicaList(input.ReplicationGroup, context),
    }));
};
var serializeAws_json1_0CreateReplicaAction = function (input, context) {
    return __assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName }));
};
var serializeAws_json1_0CreateReplicationGroupMemberAction = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.GlobalSecondaryIndexes !== undefined &&
        input.GlobalSecondaryIndexes !== null && {
        GlobalSecondaryIndexes: serializeAws_json1_0ReplicaGlobalSecondaryIndexList(input.GlobalSecondaryIndexes, context),
    })), (input.KMSMasterKeyId !== undefined &&
        input.KMSMasterKeyId !== null && { KMSMasterKeyId: input.KMSMasterKeyId })), (input.ProvisionedThroughputOverride !== undefined &&
        input.ProvisionedThroughputOverride !== null && {
        ProvisionedThroughputOverride: serializeAws_json1_0ProvisionedThroughputOverride(input.ProvisionedThroughputOverride, context),
    })), (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName })), (input.TableClassOverride !== undefined &&
        input.TableClassOverride !== null && { TableClassOverride: input.TableClassOverride }));
};
var serializeAws_json1_0CreateTableInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributeDefinitions !== undefined &&
        input.AttributeDefinitions !== null && {
        AttributeDefinitions: serializeAws_json1_0AttributeDefinitions(input.AttributeDefinitions, context),
    })), (input.BillingMode !== undefined && input.BillingMode !== null && { BillingMode: input.BillingMode })), (input.GlobalSecondaryIndexes !== undefined &&
        input.GlobalSecondaryIndexes !== null && {
        GlobalSecondaryIndexes: serializeAws_json1_0GlobalSecondaryIndexList(input.GlobalSecondaryIndexes, context),
    })), (input.KeySchema !== undefined &&
        input.KeySchema !== null && { KeySchema: serializeAws_json1_0KeySchema(input.KeySchema, context) })), (input.LocalSecondaryIndexes !== undefined &&
        input.LocalSecondaryIndexes !== null && {
        LocalSecondaryIndexes: serializeAws_json1_0LocalSecondaryIndexList(input.LocalSecondaryIndexes, context),
    })), (input.ProvisionedThroughput !== undefined &&
        input.ProvisionedThroughput !== null && {
        ProvisionedThroughput: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughput, context),
    })), (input.SSESpecification !== undefined &&
        input.SSESpecification !== null && {
        SSESpecification: serializeAws_json1_0SSESpecification(input.SSESpecification, context),
    })), (input.StreamSpecification !== undefined &&
        input.StreamSpecification !== null && {
        StreamSpecification: serializeAws_json1_0StreamSpecification(input.StreamSpecification, context),
    })), (input.TableClass !== undefined && input.TableClass !== null && { TableClass: input.TableClass })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_0TagList(input.Tags, context) }));
};
var serializeAws_json1_0Delete = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ReturnValuesOnConditionCheckFailure !== undefined &&
        input.ReturnValuesOnConditionCheckFailure !== null && {
        ReturnValuesOnConditionCheckFailure: input.ReturnValuesOnConditionCheckFailure,
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DeleteBackupInput = function (input, context) {
    return __assign({}, (input.BackupArn !== undefined && input.BackupArn !== null && { BackupArn: input.BackupArn }));
};
var serializeAws_json1_0DeleteGlobalSecondaryIndexAction = function (input, context) {
    return __assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName }));
};
var serializeAws_json1_0DeleteItemInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ConditionalOperator !== undefined &&
        input.ConditionalOperator !== null && { ConditionalOperator: input.ConditionalOperator })), (input.Expected !== undefined &&
        input.Expected !== null && { Expected: serializeAws_json1_0ExpectedAttributeMap(input.Expected, context) })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ReturnItemCollectionMetrics !== undefined &&
        input.ReturnItemCollectionMetrics !== null && { ReturnItemCollectionMetrics: input.ReturnItemCollectionMetrics })), (input.ReturnValues !== undefined && input.ReturnValues !== null && { ReturnValues: input.ReturnValues })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DeleteReplicaAction = function (input, context) {
    return __assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName }));
};
var serializeAws_json1_0DeleteReplicationGroupMemberAction = function (input, context) {
    return __assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName }));
};
var serializeAws_json1_0DeleteRequest = function (input, context) {
    return __assign({}, (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) }));
};
var serializeAws_json1_0DeleteTableInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeBackupInput = function (input, context) {
    return __assign({}, (input.BackupArn !== undefined && input.BackupArn !== null && { BackupArn: input.BackupArn }));
};
var serializeAws_json1_0DescribeContinuousBackupsInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeContributorInsightsInput = function (input, context) {
    return __assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeEndpointsRequest = function (input, context) {
    return {};
};
var serializeAws_json1_0DescribeExportInput = function (input, context) {
    return __assign({}, (input.ExportArn !== undefined && input.ExportArn !== null && { ExportArn: input.ExportArn }));
};
var serializeAws_json1_0DescribeGlobalTableInput = function (input, context) {
    return __assign({}, (input.GlobalTableName !== undefined &&
        input.GlobalTableName !== null && { GlobalTableName: input.GlobalTableName }));
};
var serializeAws_json1_0DescribeGlobalTableSettingsInput = function (input, context) {
    return __assign({}, (input.GlobalTableName !== undefined &&
        input.GlobalTableName !== null && { GlobalTableName: input.GlobalTableName }));
};
var serializeAws_json1_0DescribeKinesisStreamingDestinationInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeLimitsInput = function (input, context) {
    return {};
};
var serializeAws_json1_0DescribeTableInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeTableReplicaAutoScalingInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0DescribeTimeToLiveInput = function (input, context) {
    return __assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0ExecuteStatementInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_0PreparedStatementParameters(input.Parameters, context),
    })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.Statement !== undefined && input.Statement !== null && { Statement: input.Statement }));
};
var serializeAws_json1_0ExecuteTransactionInput = function (input, context) {
    var _a;
    return __assign(__assign({ ClientRequestToken: (_a = input.ClientRequestToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.TransactStatements !== undefined &&
        input.TransactStatements !== null && {
        TransactStatements: serializeAws_json1_0ParameterizedStatements(input.TransactStatements, context),
    }));
};
var serializeAws_json1_0ExpectedAttributeMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0ExpectedAttributeValue(value, context), _b));
    }, {});
};
var serializeAws_json1_0ExpectedAttributeValue = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AttributeValueList !== undefined &&
        input.AttributeValueList !== null && {
        AttributeValueList: serializeAws_json1_0AttributeValueList(input.AttributeValueList, context),
    })), (input.ComparisonOperator !== undefined &&
        input.ComparisonOperator !== null && { ComparisonOperator: input.ComparisonOperator })), (input.Exists !== undefined && input.Exists !== null && { Exists: input.Exists })), (input.Value !== undefined &&
        input.Value !== null && { Value: serializeAws_json1_0AttributeValue(input.Value, context) }));
};
var serializeAws_json1_0ExportTableToPointInTimeInput = function (input, context) {
    var _a;
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.ExportFormat !== undefined && input.ExportFormat !== null && { ExportFormat: input.ExportFormat })), (input.ExportTime !== undefined &&
        input.ExportTime !== null && { ExportTime: Math.round(input.ExportTime.getTime() / 1000) })), (input.S3Bucket !== undefined && input.S3Bucket !== null && { S3Bucket: input.S3Bucket })), (input.S3BucketOwner !== undefined && input.S3BucketOwner !== null && { S3BucketOwner: input.S3BucketOwner })), (input.S3Prefix !== undefined && input.S3Prefix !== null && { S3Prefix: input.S3Prefix })), (input.S3SseAlgorithm !== undefined &&
        input.S3SseAlgorithm !== null && { S3SseAlgorithm: input.S3SseAlgorithm })), (input.S3SseKmsKeyId !== undefined && input.S3SseKmsKeyId !== null && { S3SseKmsKeyId: input.S3SseKmsKeyId })), (input.TableArn !== undefined && input.TableArn !== null && { TableArn: input.TableArn }));
};
var serializeAws_json1_0ExpressionAttributeNameMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_json1_0ExpressionAttributeValueMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0AttributeValue(value, context), _b));
    }, {});
};
var serializeAws_json1_0FilterConditionMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0Condition(value, context), _b));
    }, {});
};
var serializeAws_json1_0Get = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ProjectionExpression !== undefined &&
        input.ProjectionExpression !== null && { ProjectionExpression: input.ProjectionExpression })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0GetItemInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributesToGet !== undefined &&
        input.AttributesToGet !== null && {
        AttributesToGet: serializeAws_json1_0AttributeNameList(input.AttributesToGet, context),
    })), (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ProjectionExpression !== undefined &&
        input.ProjectionExpression !== null && { ProjectionExpression: input.ProjectionExpression })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0GlobalSecondaryIndex = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.KeySchema !== undefined &&
        input.KeySchema !== null && { KeySchema: serializeAws_json1_0KeySchema(input.KeySchema, context) })), (input.Projection !== undefined &&
        input.Projection !== null && { Projection: serializeAws_json1_0Projection(input.Projection, context) })), (input.ProvisionedThroughput !== undefined &&
        input.ProvisionedThroughput !== null && {
        ProvisionedThroughput: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughput, context),
    }));
};
var serializeAws_json1_0GlobalSecondaryIndexAutoScalingUpdate = function (input, context) {
    return __assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedWriteCapacityAutoScalingUpdate !== undefined &&
        input.ProvisionedWriteCapacityAutoScalingUpdate !== null && {
        ProvisionedWriteCapacityAutoScalingUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ProvisionedWriteCapacityAutoScalingUpdate, context),
    }));
};
var serializeAws_json1_0GlobalSecondaryIndexAutoScalingUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0GlobalSecondaryIndexAutoScalingUpdate(entry, context);
    });
};
var serializeAws_json1_0GlobalSecondaryIndexList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0GlobalSecondaryIndex(entry, context);
    });
};
var serializeAws_json1_0GlobalSecondaryIndexUpdate = function (input, context) {
    return __assign(__assign(__assign({}, (input.Create !== undefined &&
        input.Create !== null && { Create: serializeAws_json1_0CreateGlobalSecondaryIndexAction(input.Create, context) })), (input.Delete !== undefined &&
        input.Delete !== null && { Delete: serializeAws_json1_0DeleteGlobalSecondaryIndexAction(input.Delete, context) })), (input.Update !== undefined &&
        input.Update !== null && { Update: serializeAws_json1_0UpdateGlobalSecondaryIndexAction(input.Update, context) }));
};
var serializeAws_json1_0GlobalSecondaryIndexUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0GlobalSecondaryIndexUpdate(entry, context);
    });
};
var serializeAws_json1_0GlobalTableGlobalSecondaryIndexSettingsUpdate = function (input, context) {
    return __assign(__assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedWriteCapacityAutoScalingSettingsUpdate !== undefined &&
        input.ProvisionedWriteCapacityAutoScalingSettingsUpdate !== null && {
        ProvisionedWriteCapacityAutoScalingSettingsUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ProvisionedWriteCapacityAutoScalingSettingsUpdate, context),
    })), (input.ProvisionedWriteCapacityUnits !== undefined &&
        input.ProvisionedWriteCapacityUnits !== null && {
        ProvisionedWriteCapacityUnits: input.ProvisionedWriteCapacityUnits,
    }));
};
var serializeAws_json1_0GlobalTableGlobalSecondaryIndexSettingsUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0GlobalTableGlobalSecondaryIndexSettingsUpdate(entry, context);
    });
};
var serializeAws_json1_0Key = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0AttributeValue(value, context), _b));
    }, {});
};
var serializeAws_json1_0KeyConditions = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0Condition(value, context), _b));
    }, {});
};
var serializeAws_json1_0KeyList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0Key(entry, context);
    });
};
var serializeAws_json1_0KeysAndAttributes = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AttributesToGet !== undefined &&
        input.AttributesToGet !== null && {
        AttributesToGet: serializeAws_json1_0AttributeNameList(input.AttributesToGet, context),
    })), (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.Keys !== undefined && input.Keys !== null && { Keys: serializeAws_json1_0KeyList(input.Keys, context) })), (input.ProjectionExpression !== undefined &&
        input.ProjectionExpression !== null && { ProjectionExpression: input.ProjectionExpression }));
};
var serializeAws_json1_0KeySchema = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0KeySchemaElement(entry, context);
    });
};
var serializeAws_json1_0KeySchemaElement = function (input, context) {
    return __assign(__assign({}, (input.AttributeName !== undefined && input.AttributeName !== null && { AttributeName: input.AttributeName })), (input.KeyType !== undefined && input.KeyType !== null && { KeyType: input.KeyType }));
};
var serializeAws_json1_0KinesisStreamingDestinationInput = function (input, context) {
    return __assign(__assign({}, (input.StreamArn !== undefined && input.StreamArn !== null && { StreamArn: input.StreamArn })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0ListAttributeValue = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0AttributeValue(entry, context);
    });
};
var serializeAws_json1_0ListBackupsInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.BackupType !== undefined && input.BackupType !== null && { BackupType: input.BackupType })), (input.ExclusiveStartBackupArn !== undefined &&
        input.ExclusiveStartBackupArn !== null && { ExclusiveStartBackupArn: input.ExclusiveStartBackupArn })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.TimeRangeLowerBound !== undefined &&
        input.TimeRangeLowerBound !== null && {
        TimeRangeLowerBound: Math.round(input.TimeRangeLowerBound.getTime() / 1000),
    })), (input.TimeRangeUpperBound !== undefined &&
        input.TimeRangeUpperBound !== null && {
        TimeRangeUpperBound: Math.round(input.TimeRangeUpperBound.getTime() / 1000),
    }));
};
var serializeAws_json1_0ListContributorInsightsInput = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0ListExportsInput = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.TableArn !== undefined && input.TableArn !== null && { TableArn: input.TableArn }));
};
var serializeAws_json1_0ListGlobalTablesInput = function (input, context) {
    return __assign(__assign(__assign({}, (input.ExclusiveStartGlobalTableName !== undefined &&
        input.ExclusiveStartGlobalTableName !== null && {
        ExclusiveStartGlobalTableName: input.ExclusiveStartGlobalTableName,
    })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit })), (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName }));
};
var serializeAws_json1_0ListTablesInput = function (input, context) {
    return __assign(__assign({}, (input.ExclusiveStartTableName !== undefined &&
        input.ExclusiveStartTableName !== null && { ExclusiveStartTableName: input.ExclusiveStartTableName })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit }));
};
var serializeAws_json1_0ListTagsOfResourceInput = function (input, context) {
    return __assign(__assign({}, (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResourceArn !== undefined && input.ResourceArn !== null && { ResourceArn: input.ResourceArn }));
};
var serializeAws_json1_0LocalSecondaryIndex = function (input, context) {
    return __assign(__assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.KeySchema !== undefined &&
        input.KeySchema !== null && { KeySchema: serializeAws_json1_0KeySchema(input.KeySchema, context) })), (input.Projection !== undefined &&
        input.Projection !== null && { Projection: serializeAws_json1_0Projection(input.Projection, context) }));
};
var serializeAws_json1_0LocalSecondaryIndexList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0LocalSecondaryIndex(entry, context);
    });
};
var serializeAws_json1_0MapAttributeValue = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0AttributeValue(value, context), _b));
    }, {});
};
var serializeAws_json1_0NonKeyAttributeNameList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_0NumberSetAttributeValue = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_0ParameterizedStatement = function (input, context) {
    return __assign(__assign({}, (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_0PreparedStatementParameters(input.Parameters, context),
    })), (input.Statement !== undefined && input.Statement !== null && { Statement: input.Statement }));
};
var serializeAws_json1_0ParameterizedStatements = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ParameterizedStatement(entry, context);
    });
};
var serializeAws_json1_0PartiQLBatchRequest = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0BatchStatementRequest(entry, context);
    });
};
var serializeAws_json1_0PointInTimeRecoverySpecification = function (input, context) {
    return __assign({}, (input.PointInTimeRecoveryEnabled !== undefined &&
        input.PointInTimeRecoveryEnabled !== null && { PointInTimeRecoveryEnabled: input.PointInTimeRecoveryEnabled }));
};
var serializeAws_json1_0PreparedStatementParameters = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0AttributeValue(entry, context);
    });
};
var serializeAws_json1_0Projection = function (input, context) {
    return __assign(__assign({}, (input.NonKeyAttributes !== undefined &&
        input.NonKeyAttributes !== null && {
        NonKeyAttributes: serializeAws_json1_0NonKeyAttributeNameList(input.NonKeyAttributes, context),
    })), (input.ProjectionType !== undefined &&
        input.ProjectionType !== null && { ProjectionType: input.ProjectionType }));
};
var serializeAws_json1_0ProvisionedThroughput = function (input, context) {
    return __assign(__assign({}, (input.ReadCapacityUnits !== undefined &&
        input.ReadCapacityUnits !== null && { ReadCapacityUnits: input.ReadCapacityUnits })), (input.WriteCapacityUnits !== undefined &&
        input.WriteCapacityUnits !== null && { WriteCapacityUnits: input.WriteCapacityUnits }));
};
var serializeAws_json1_0ProvisionedThroughputOverride = function (input, context) {
    return __assign({}, (input.ReadCapacityUnits !== undefined &&
        input.ReadCapacityUnits !== null && { ReadCapacityUnits: input.ReadCapacityUnits }));
};
var serializeAws_json1_0Put = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Item !== undefined &&
        input.Item !== null && { Item: serializeAws_json1_0PutItemInputAttributeMap(input.Item, context) })), (input.ReturnValuesOnConditionCheckFailure !== undefined &&
        input.ReturnValuesOnConditionCheckFailure !== null && {
        ReturnValuesOnConditionCheckFailure: input.ReturnValuesOnConditionCheckFailure,
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0PutItemInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ConditionalOperator !== undefined &&
        input.ConditionalOperator !== null && { ConditionalOperator: input.ConditionalOperator })), (input.Expected !== undefined &&
        input.Expected !== null && { Expected: serializeAws_json1_0ExpectedAttributeMap(input.Expected, context) })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Item !== undefined &&
        input.Item !== null && { Item: serializeAws_json1_0PutItemInputAttributeMap(input.Item, context) })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ReturnItemCollectionMetrics !== undefined &&
        input.ReturnItemCollectionMetrics !== null && { ReturnItemCollectionMetrics: input.ReturnItemCollectionMetrics })), (input.ReturnValues !== undefined && input.ReturnValues !== null && { ReturnValues: input.ReturnValues })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0PutItemInputAttributeMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_0AttributeValue(value, context), _b));
    }, {});
};
var serializeAws_json1_0PutRequest = function (input, context) {
    return __assign({}, (input.Item !== undefined &&
        input.Item !== null && { Item: serializeAws_json1_0PutItemInputAttributeMap(input.Item, context) }));
};
var serializeAws_json1_0QueryInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributesToGet !== undefined &&
        input.AttributesToGet !== null && {
        AttributesToGet: serializeAws_json1_0AttributeNameList(input.AttributesToGet, context),
    })), (input.ConditionalOperator !== undefined &&
        input.ConditionalOperator !== null && { ConditionalOperator: input.ConditionalOperator })), (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.ExclusiveStartKey !== undefined &&
        input.ExclusiveStartKey !== null && {
        ExclusiveStartKey: serializeAws_json1_0Key(input.ExclusiveStartKey, context),
    })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.FilterExpression !== undefined &&
        input.FilterExpression !== null && { FilterExpression: input.FilterExpression })), (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.KeyConditionExpression !== undefined &&
        input.KeyConditionExpression !== null && { KeyConditionExpression: input.KeyConditionExpression })), (input.KeyConditions !== undefined &&
        input.KeyConditions !== null && {
        KeyConditions: serializeAws_json1_0KeyConditions(input.KeyConditions, context),
    })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit })), (input.ProjectionExpression !== undefined &&
        input.ProjectionExpression !== null && { ProjectionExpression: input.ProjectionExpression })), (input.QueryFilter !== undefined &&
        input.QueryFilter !== null && {
        QueryFilter: serializeAws_json1_0FilterConditionMap(input.QueryFilter, context),
    })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ScanIndexForward !== undefined &&
        input.ScanIndexForward !== null && { ScanIndexForward: input.ScanIndexForward })), (input.Select !== undefined && input.Select !== null && { Select: input.Select })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0Replica = function (input, context) {
    return __assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName }));
};
var serializeAws_json1_0ReplicaAutoScalingUpdate = function (input, context) {
    return __assign(__assign(__assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName })), (input.ReplicaGlobalSecondaryIndexUpdates !== undefined &&
        input.ReplicaGlobalSecondaryIndexUpdates !== null && {
        ReplicaGlobalSecondaryIndexUpdates: serializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingUpdateList(input.ReplicaGlobalSecondaryIndexUpdates, context),
    })), (input.ReplicaProvisionedReadCapacityAutoScalingUpdate !== undefined &&
        input.ReplicaProvisionedReadCapacityAutoScalingUpdate !== null && {
        ReplicaProvisionedReadCapacityAutoScalingUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ReplicaProvisionedReadCapacityAutoScalingUpdate, context),
    }));
};
var serializeAws_json1_0ReplicaAutoScalingUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaAutoScalingUpdate(entry, context);
    });
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndex = function (input, context) {
    return __assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedThroughputOverride !== undefined &&
        input.ProvisionedThroughputOverride !== null && {
        ProvisionedThroughputOverride: serializeAws_json1_0ProvisionedThroughputOverride(input.ProvisionedThroughputOverride, context),
    }));
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingUpdate = function (input, context) {
    return __assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedReadCapacityAutoScalingUpdate !== undefined &&
        input.ProvisionedReadCapacityAutoScalingUpdate !== null && {
        ProvisionedReadCapacityAutoScalingUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ProvisionedReadCapacityAutoScalingUpdate, context),
    }));
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingUpdate(entry, context);
    });
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndexList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaGlobalSecondaryIndex(entry, context);
    });
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsUpdate = function (input, context) {
    return __assign(__assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedReadCapacityAutoScalingSettingsUpdate !== undefined &&
        input.ProvisionedReadCapacityAutoScalingSettingsUpdate !== null && {
        ProvisionedReadCapacityAutoScalingSettingsUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ProvisionedReadCapacityAutoScalingSettingsUpdate, context),
    })), (input.ProvisionedReadCapacityUnits !== undefined &&
        input.ProvisionedReadCapacityUnits !== null && {
        ProvisionedReadCapacityUnits: input.ProvisionedReadCapacityUnits,
    }));
};
var serializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsUpdate(entry, context);
    });
};
var serializeAws_json1_0ReplicaList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0Replica(entry, context);
    });
};
var serializeAws_json1_0ReplicaSettingsUpdate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName })), (input.ReplicaGlobalSecondaryIndexSettingsUpdate !== undefined &&
        input.ReplicaGlobalSecondaryIndexSettingsUpdate !== null && {
        ReplicaGlobalSecondaryIndexSettingsUpdate: serializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsUpdateList(input.ReplicaGlobalSecondaryIndexSettingsUpdate, context),
    })), (input.ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate !== undefined &&
        input.ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate !== null && {
        ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate, context),
    })), (input.ReplicaProvisionedReadCapacityUnits !== undefined &&
        input.ReplicaProvisionedReadCapacityUnits !== null && {
        ReplicaProvisionedReadCapacityUnits: input.ReplicaProvisionedReadCapacityUnits,
    })), (input.ReplicaTableClass !== undefined &&
        input.ReplicaTableClass !== null && { ReplicaTableClass: input.ReplicaTableClass }));
};
var serializeAws_json1_0ReplicaSettingsUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaSettingsUpdate(entry, context);
    });
};
var serializeAws_json1_0ReplicationGroupUpdate = function (input, context) {
    return __assign(__assign(__assign({}, (input.Create !== undefined &&
        input.Create !== null && {
        Create: serializeAws_json1_0CreateReplicationGroupMemberAction(input.Create, context),
    })), (input.Delete !== undefined &&
        input.Delete !== null && {
        Delete: serializeAws_json1_0DeleteReplicationGroupMemberAction(input.Delete, context),
    })), (input.Update !== undefined &&
        input.Update !== null && {
        Update: serializeAws_json1_0UpdateReplicationGroupMemberAction(input.Update, context),
    }));
};
var serializeAws_json1_0ReplicationGroupUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicationGroupUpdate(entry, context);
    });
};
var serializeAws_json1_0ReplicaUpdate = function (input, context) {
    return __assign(__assign({}, (input.Create !== undefined &&
        input.Create !== null && { Create: serializeAws_json1_0CreateReplicaAction(input.Create, context) })), (input.Delete !== undefined &&
        input.Delete !== null && { Delete: serializeAws_json1_0DeleteReplicaAction(input.Delete, context) }));
};
var serializeAws_json1_0ReplicaUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0ReplicaUpdate(entry, context);
    });
};
var serializeAws_json1_0RestoreTableFromBackupInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BackupArn !== undefined && input.BackupArn !== null && { BackupArn: input.BackupArn })), (input.BillingModeOverride !== undefined &&
        input.BillingModeOverride !== null && { BillingModeOverride: input.BillingModeOverride })), (input.GlobalSecondaryIndexOverride !== undefined &&
        input.GlobalSecondaryIndexOverride !== null && {
        GlobalSecondaryIndexOverride: serializeAws_json1_0GlobalSecondaryIndexList(input.GlobalSecondaryIndexOverride, context),
    })), (input.LocalSecondaryIndexOverride !== undefined &&
        input.LocalSecondaryIndexOverride !== null && {
        LocalSecondaryIndexOverride: serializeAws_json1_0LocalSecondaryIndexList(input.LocalSecondaryIndexOverride, context),
    })), (input.ProvisionedThroughputOverride !== undefined &&
        input.ProvisionedThroughputOverride !== null && {
        ProvisionedThroughputOverride: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughputOverride, context),
    })), (input.SSESpecificationOverride !== undefined &&
        input.SSESpecificationOverride !== null && {
        SSESpecificationOverride: serializeAws_json1_0SSESpecification(input.SSESpecificationOverride, context),
    })), (input.TargetTableName !== undefined &&
        input.TargetTableName !== null && { TargetTableName: input.TargetTableName }));
};
var serializeAws_json1_0RestoreTableToPointInTimeInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BillingModeOverride !== undefined &&
        input.BillingModeOverride !== null && { BillingModeOverride: input.BillingModeOverride })), (input.GlobalSecondaryIndexOverride !== undefined &&
        input.GlobalSecondaryIndexOverride !== null && {
        GlobalSecondaryIndexOverride: serializeAws_json1_0GlobalSecondaryIndexList(input.GlobalSecondaryIndexOverride, context),
    })), (input.LocalSecondaryIndexOverride !== undefined &&
        input.LocalSecondaryIndexOverride !== null && {
        LocalSecondaryIndexOverride: serializeAws_json1_0LocalSecondaryIndexList(input.LocalSecondaryIndexOverride, context),
    })), (input.ProvisionedThroughputOverride !== undefined &&
        input.ProvisionedThroughputOverride !== null && {
        ProvisionedThroughputOverride: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughputOverride, context),
    })), (input.RestoreDateTime !== undefined &&
        input.RestoreDateTime !== null && { RestoreDateTime: Math.round(input.RestoreDateTime.getTime() / 1000) })), (input.SSESpecificationOverride !== undefined &&
        input.SSESpecificationOverride !== null && {
        SSESpecificationOverride: serializeAws_json1_0SSESpecification(input.SSESpecificationOverride, context),
    })), (input.SourceTableArn !== undefined &&
        input.SourceTableArn !== null && { SourceTableArn: input.SourceTableArn })), (input.SourceTableName !== undefined &&
        input.SourceTableName !== null && { SourceTableName: input.SourceTableName })), (input.TargetTableName !== undefined &&
        input.TargetTableName !== null && { TargetTableName: input.TargetTableName })), (input.UseLatestRestorableTime !== undefined &&
        input.UseLatestRestorableTime !== null && { UseLatestRestorableTime: input.UseLatestRestorableTime }));
};
var serializeAws_json1_0ScanInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributesToGet !== undefined &&
        input.AttributesToGet !== null && {
        AttributesToGet: serializeAws_json1_0AttributeNameList(input.AttributesToGet, context),
    })), (input.ConditionalOperator !== undefined &&
        input.ConditionalOperator !== null && { ConditionalOperator: input.ConditionalOperator })), (input.ConsistentRead !== undefined &&
        input.ConsistentRead !== null && { ConsistentRead: input.ConsistentRead })), (input.ExclusiveStartKey !== undefined &&
        input.ExclusiveStartKey !== null && {
        ExclusiveStartKey: serializeAws_json1_0Key(input.ExclusiveStartKey, context),
    })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.FilterExpression !== undefined &&
        input.FilterExpression !== null && { FilterExpression: input.FilterExpression })), (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.Limit !== undefined && input.Limit !== null && { Limit: input.Limit })), (input.ProjectionExpression !== undefined &&
        input.ProjectionExpression !== null && { ProjectionExpression: input.ProjectionExpression })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ScanFilter !== undefined &&
        input.ScanFilter !== null && { ScanFilter: serializeAws_json1_0FilterConditionMap(input.ScanFilter, context) })), (input.Segment !== undefined && input.Segment !== null && { Segment: input.Segment })), (input.Select !== undefined && input.Select !== null && { Select: input.Select })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.TotalSegments !== undefined && input.TotalSegments !== null && { TotalSegments: input.TotalSegments }));
};
var serializeAws_json1_0SSESpecification = function (input, context) {
    return __assign(__assign(__assign({}, (input.Enabled !== undefined && input.Enabled !== null && { Enabled: input.Enabled })), (input.KMSMasterKeyId !== undefined &&
        input.KMSMasterKeyId !== null && { KMSMasterKeyId: input.KMSMasterKeyId })), (input.SSEType !== undefined && input.SSEType !== null && { SSEType: input.SSEType }));
};
var serializeAws_json1_0StreamSpecification = function (input, context) {
    return __assign(__assign({}, (input.StreamEnabled !== undefined && input.StreamEnabled !== null && { StreamEnabled: input.StreamEnabled })), (input.StreamViewType !== undefined &&
        input.StreamViewType !== null && { StreamViewType: input.StreamViewType }));
};
var serializeAws_json1_0StringSetAttributeValue = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_0Tag = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_0TagKeyList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_0TagList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0Tag(entry, context);
    });
};
var serializeAws_json1_0TagResourceInput = function (input, context) {
    return __assign(__assign({}, (input.ResourceArn !== undefined && input.ResourceArn !== null && { ResourceArn: input.ResourceArn })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_0TagList(input.Tags, context) }));
};
var serializeAws_json1_0TimeToLiveSpecification = function (input, context) {
    return __assign(__assign({}, (input.AttributeName !== undefined && input.AttributeName !== null && { AttributeName: input.AttributeName })), (input.Enabled !== undefined && input.Enabled !== null && { Enabled: input.Enabled }));
};
var serializeAws_json1_0TransactGetItem = function (input, context) {
    return __assign({}, (input.Get !== undefined && input.Get !== null && { Get: serializeAws_json1_0Get(input.Get, context) }));
};
var serializeAws_json1_0TransactGetItemList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0TransactGetItem(entry, context);
    });
};
var serializeAws_json1_0TransactGetItemsInput = function (input, context) {
    return __assign(__assign({}, (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.TransactItems !== undefined &&
        input.TransactItems !== null && {
        TransactItems: serializeAws_json1_0TransactGetItemList(input.TransactItems, context),
    }));
};
var serializeAws_json1_0TransactWriteItem = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.ConditionCheck !== undefined &&
        input.ConditionCheck !== null && {
        ConditionCheck: serializeAws_json1_0ConditionCheck(input.ConditionCheck, context),
    })), (input.Delete !== undefined &&
        input.Delete !== null && { Delete: serializeAws_json1_0Delete(input.Delete, context) })), (input.Put !== undefined && input.Put !== null && { Put: serializeAws_json1_0Put(input.Put, context) })), (input.Update !== undefined &&
        input.Update !== null && { Update: serializeAws_json1_0Update(input.Update, context) }));
};
var serializeAws_json1_0TransactWriteItemList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0TransactWriteItem(entry, context);
    });
};
var serializeAws_json1_0TransactWriteItemsInput = function (input, context) {
    var _a;
    return __assign(__assign(__assign({ ClientRequestToken: (_a = input.ClientRequestToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ReturnItemCollectionMetrics !== undefined &&
        input.ReturnItemCollectionMetrics !== null && { ReturnItemCollectionMetrics: input.ReturnItemCollectionMetrics })), (input.TransactItems !== undefined &&
        input.TransactItems !== null && {
        TransactItems: serializeAws_json1_0TransactWriteItemList(input.TransactItems, context),
    }));
};
var serializeAws_json1_0UntagResourceInput = function (input, context) {
    return __assign(__assign({}, (input.ResourceArn !== undefined && input.ResourceArn !== null && { ResourceArn: input.ResourceArn })), (input.TagKeys !== undefined &&
        input.TagKeys !== null && { TagKeys: serializeAws_json1_0TagKeyList(input.TagKeys, context) }));
};
var serializeAws_json1_0Update = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ReturnValuesOnConditionCheckFailure !== undefined &&
        input.ReturnValuesOnConditionCheckFailure !== null && {
        ReturnValuesOnConditionCheckFailure: input.ReturnValuesOnConditionCheckFailure,
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.UpdateExpression !== undefined &&
        input.UpdateExpression !== null && { UpdateExpression: input.UpdateExpression }));
};
var serializeAws_json1_0UpdateContinuousBackupsInput = function (input, context) {
    return __assign(__assign({}, (input.PointInTimeRecoverySpecification !== undefined &&
        input.PointInTimeRecoverySpecification !== null && {
        PointInTimeRecoverySpecification: serializeAws_json1_0PointInTimeRecoverySpecification(input.PointInTimeRecoverySpecification, context),
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0UpdateContributorInsightsInput = function (input, context) {
    return __assign(__assign(__assign({}, (input.ContributorInsightsAction !== undefined &&
        input.ContributorInsightsAction !== null && { ContributorInsightsAction: input.ContributorInsightsAction })), (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0UpdateGlobalSecondaryIndexAction = function (input, context) {
    return __assign(__assign({}, (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.ProvisionedThroughput !== undefined &&
        input.ProvisionedThroughput !== null && {
        ProvisionedThroughput: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughput, context),
    }));
};
var serializeAws_json1_0UpdateGlobalTableInput = function (input, context) {
    return __assign(__assign({}, (input.GlobalTableName !== undefined &&
        input.GlobalTableName !== null && { GlobalTableName: input.GlobalTableName })), (input.ReplicaUpdates !== undefined &&
        input.ReplicaUpdates !== null && {
        ReplicaUpdates: serializeAws_json1_0ReplicaUpdateList(input.ReplicaUpdates, context),
    }));
};
var serializeAws_json1_0UpdateGlobalTableSettingsInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.GlobalTableBillingMode !== undefined &&
        input.GlobalTableBillingMode !== null && { GlobalTableBillingMode: input.GlobalTableBillingMode })), (input.GlobalTableGlobalSecondaryIndexSettingsUpdate !== undefined &&
        input.GlobalTableGlobalSecondaryIndexSettingsUpdate !== null && {
        GlobalTableGlobalSecondaryIndexSettingsUpdate: serializeAws_json1_0GlobalTableGlobalSecondaryIndexSettingsUpdateList(input.GlobalTableGlobalSecondaryIndexSettingsUpdate, context),
    })), (input.GlobalTableName !== undefined &&
        input.GlobalTableName !== null && { GlobalTableName: input.GlobalTableName })), (input.GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate !== undefined &&
        input.GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate !== null && {
        GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate, context),
    })), (input.GlobalTableProvisionedWriteCapacityUnits !== undefined &&
        input.GlobalTableProvisionedWriteCapacityUnits !== null && {
        GlobalTableProvisionedWriteCapacityUnits: input.GlobalTableProvisionedWriteCapacityUnits,
    })), (input.ReplicaSettingsUpdate !== undefined &&
        input.ReplicaSettingsUpdate !== null && {
        ReplicaSettingsUpdate: serializeAws_json1_0ReplicaSettingsUpdateList(input.ReplicaSettingsUpdate, context),
    }));
};
var serializeAws_json1_0UpdateItemInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributeUpdates !== undefined &&
        input.AttributeUpdates !== null && {
        AttributeUpdates: serializeAws_json1_0AttributeUpdates(input.AttributeUpdates, context),
    })), (input.ConditionExpression !== undefined &&
        input.ConditionExpression !== null && { ConditionExpression: input.ConditionExpression })), (input.ConditionalOperator !== undefined &&
        input.ConditionalOperator !== null && { ConditionalOperator: input.ConditionalOperator })), (input.Expected !== undefined &&
        input.Expected !== null && { Expected: serializeAws_json1_0ExpectedAttributeMap(input.Expected, context) })), (input.ExpressionAttributeNames !== undefined &&
        input.ExpressionAttributeNames !== null && {
        ExpressionAttributeNames: serializeAws_json1_0ExpressionAttributeNameMap(input.ExpressionAttributeNames, context),
    })), (input.ExpressionAttributeValues !== undefined &&
        input.ExpressionAttributeValues !== null && {
        ExpressionAttributeValues: serializeAws_json1_0ExpressionAttributeValueMap(input.ExpressionAttributeValues, context),
    })), (input.Key !== undefined && input.Key !== null && { Key: serializeAws_json1_0Key(input.Key, context) })), (input.ReturnConsumedCapacity !== undefined &&
        input.ReturnConsumedCapacity !== null && { ReturnConsumedCapacity: input.ReturnConsumedCapacity })), (input.ReturnItemCollectionMetrics !== undefined &&
        input.ReturnItemCollectionMetrics !== null && { ReturnItemCollectionMetrics: input.ReturnItemCollectionMetrics })), (input.ReturnValues !== undefined && input.ReturnValues !== null && { ReturnValues: input.ReturnValues })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.UpdateExpression !== undefined &&
        input.UpdateExpression !== null && { UpdateExpression: input.UpdateExpression }));
};
var serializeAws_json1_0UpdateReplicationGroupMemberAction = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.GlobalSecondaryIndexes !== undefined &&
        input.GlobalSecondaryIndexes !== null && {
        GlobalSecondaryIndexes: serializeAws_json1_0ReplicaGlobalSecondaryIndexList(input.GlobalSecondaryIndexes, context),
    })), (input.KMSMasterKeyId !== undefined &&
        input.KMSMasterKeyId !== null && { KMSMasterKeyId: input.KMSMasterKeyId })), (input.ProvisionedThroughputOverride !== undefined &&
        input.ProvisionedThroughputOverride !== null && {
        ProvisionedThroughputOverride: serializeAws_json1_0ProvisionedThroughputOverride(input.ProvisionedThroughputOverride, context),
    })), (input.RegionName !== undefined && input.RegionName !== null && { RegionName: input.RegionName })), (input.TableClassOverride !== undefined &&
        input.TableClassOverride !== null && { TableClassOverride: input.TableClassOverride }));
};
var serializeAws_json1_0UpdateTableInput = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AttributeDefinitions !== undefined &&
        input.AttributeDefinitions !== null && {
        AttributeDefinitions: serializeAws_json1_0AttributeDefinitions(input.AttributeDefinitions, context),
    })), (input.BillingMode !== undefined && input.BillingMode !== null && { BillingMode: input.BillingMode })), (input.GlobalSecondaryIndexUpdates !== undefined &&
        input.GlobalSecondaryIndexUpdates !== null && {
        GlobalSecondaryIndexUpdates: serializeAws_json1_0GlobalSecondaryIndexUpdateList(input.GlobalSecondaryIndexUpdates, context),
    })), (input.ProvisionedThroughput !== undefined &&
        input.ProvisionedThroughput !== null && {
        ProvisionedThroughput: serializeAws_json1_0ProvisionedThroughput(input.ProvisionedThroughput, context),
    })), (input.ReplicaUpdates !== undefined &&
        input.ReplicaUpdates !== null && {
        ReplicaUpdates: serializeAws_json1_0ReplicationGroupUpdateList(input.ReplicaUpdates, context),
    })), (input.SSESpecification !== undefined &&
        input.SSESpecification !== null && {
        SSESpecification: serializeAws_json1_0SSESpecification(input.SSESpecification, context),
    })), (input.StreamSpecification !== undefined &&
        input.StreamSpecification !== null && {
        StreamSpecification: serializeAws_json1_0StreamSpecification(input.StreamSpecification, context),
    })), (input.TableClass !== undefined && input.TableClass !== null && { TableClass: input.TableClass })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0UpdateTableReplicaAutoScalingInput = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.GlobalSecondaryIndexUpdates !== undefined &&
        input.GlobalSecondaryIndexUpdates !== null && {
        GlobalSecondaryIndexUpdates: serializeAws_json1_0GlobalSecondaryIndexAutoScalingUpdateList(input.GlobalSecondaryIndexUpdates, context),
    })), (input.ProvisionedWriteCapacityAutoScalingUpdate !== undefined &&
        input.ProvisionedWriteCapacityAutoScalingUpdate !== null && {
        ProvisionedWriteCapacityAutoScalingUpdate: serializeAws_json1_0AutoScalingSettingsUpdate(input.ProvisionedWriteCapacityAutoScalingUpdate, context),
    })), (input.ReplicaUpdates !== undefined &&
        input.ReplicaUpdates !== null && {
        ReplicaUpdates: serializeAws_json1_0ReplicaAutoScalingUpdateList(input.ReplicaUpdates, context),
    })), (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName }));
};
var serializeAws_json1_0UpdateTimeToLiveInput = function (input, context) {
    return __assign(__assign({}, (input.TableName !== undefined && input.TableName !== null && { TableName: input.TableName })), (input.TimeToLiveSpecification !== undefined &&
        input.TimeToLiveSpecification !== null && {
        TimeToLiveSpecification: serializeAws_json1_0TimeToLiveSpecification(input.TimeToLiveSpecification, context),
    }));
};
var serializeAws_json1_0WriteRequest = function (input, context) {
    return __assign(__assign({}, (input.DeleteRequest !== undefined &&
        input.DeleteRequest !== null && {
        DeleteRequest: serializeAws_json1_0DeleteRequest(input.DeleteRequest, context),
    })), (input.PutRequest !== undefined &&
        input.PutRequest !== null && { PutRequest: serializeAws_json1_0PutRequest(input.PutRequest, context) }));
};
var serializeAws_json1_0WriteRequests = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_0WriteRequest(entry, context);
    });
};
var deserializeAws_json1_0ArchivalSummary = function (output, context) {
    return {
        ArchivalBackupArn: __expectString(output.ArchivalBackupArn),
        ArchivalDateTime: output.ArchivalDateTime !== undefined && output.ArchivalDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ArchivalDateTime)))
            : undefined,
        ArchivalReason: __expectString(output.ArchivalReason),
    };
};
var deserializeAws_json1_0AttributeDefinition = function (output, context) {
    return {
        AttributeName: __expectString(output.AttributeName),
        AttributeType: __expectString(output.AttributeType),
    };
};
var deserializeAws_json1_0AttributeDefinitions = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0AttributeDefinition(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0AttributeMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0AttributeValue(__expectUnion(value), context), _b));
    }, {});
};
var deserializeAws_json1_0AttributeNameList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0AttributeValue = function (output, context) {
    if (output.B !== undefined && output.B !== null) {
        return {
            B: context.base64Decoder(output.B),
        };
    }
    if (__expectBoolean(output.BOOL) !== undefined) {
        return { BOOL: __expectBoolean(output.BOOL) };
    }
    if (output.BS !== undefined && output.BS !== null) {
        return {
            BS: deserializeAws_json1_0BinarySetAttributeValue(output.BS, context),
        };
    }
    if (output.L !== undefined && output.L !== null) {
        return {
            L: deserializeAws_json1_0ListAttributeValue(output.L, context),
        };
    }
    if (output.M !== undefined && output.M !== null) {
        return {
            M: deserializeAws_json1_0MapAttributeValue(output.M, context),
        };
    }
    if (__expectString(output.N) !== undefined) {
        return { N: __expectString(output.N) };
    }
    if (output.NS !== undefined && output.NS !== null) {
        return {
            NS: deserializeAws_json1_0NumberSetAttributeValue(output.NS, context),
        };
    }
    if (__expectBoolean(output.NULL) !== undefined) {
        return { NULL: __expectBoolean(output.NULL) };
    }
    if (__expectString(output.S) !== undefined) {
        return { S: __expectString(output.S) };
    }
    if (output.SS !== undefined && output.SS !== null) {
        return {
            SS: deserializeAws_json1_0StringSetAttributeValue(output.SS, context),
        };
    }
    return { $unknown: Object.entries(output)[0] };
};
var deserializeAws_json1_0AutoScalingPolicyDescription = function (output, context) {
    return {
        PolicyName: __expectString(output.PolicyName),
        TargetTrackingScalingPolicyConfiguration: output.TargetTrackingScalingPolicyConfiguration !== undefined &&
            output.TargetTrackingScalingPolicyConfiguration !== null
            ? deserializeAws_json1_0AutoScalingTargetTrackingScalingPolicyConfigurationDescription(output.TargetTrackingScalingPolicyConfiguration, context)
            : undefined,
    };
};
var deserializeAws_json1_0AutoScalingPolicyDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0AutoScalingPolicyDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0AutoScalingSettingsDescription = function (output, context) {
    return {
        AutoScalingDisabled: __expectBoolean(output.AutoScalingDisabled),
        AutoScalingRoleArn: __expectString(output.AutoScalingRoleArn),
        MaximumUnits: __expectLong(output.MaximumUnits),
        MinimumUnits: __expectLong(output.MinimumUnits),
        ScalingPolicies: output.ScalingPolicies !== undefined && output.ScalingPolicies !== null
            ? deserializeAws_json1_0AutoScalingPolicyDescriptionList(output.ScalingPolicies, context)
            : undefined,
    };
};
var deserializeAws_json1_0AutoScalingTargetTrackingScalingPolicyConfigurationDescription = function (output, context) {
    return {
        DisableScaleIn: __expectBoolean(output.DisableScaleIn),
        ScaleInCooldown: __expectInt32(output.ScaleInCooldown),
        ScaleOutCooldown: __expectInt32(output.ScaleOutCooldown),
        TargetValue: __limitedParseDouble(output.TargetValue),
    };
};
var deserializeAws_json1_0BackupDescription = function (output, context) {
    return {
        BackupDetails: output.BackupDetails !== undefined && output.BackupDetails !== null
            ? deserializeAws_json1_0BackupDetails(output.BackupDetails, context)
            : undefined,
        SourceTableDetails: output.SourceTableDetails !== undefined && output.SourceTableDetails !== null
            ? deserializeAws_json1_0SourceTableDetails(output.SourceTableDetails, context)
            : undefined,
        SourceTableFeatureDetails: output.SourceTableFeatureDetails !== undefined && output.SourceTableFeatureDetails !== null
            ? deserializeAws_json1_0SourceTableFeatureDetails(output.SourceTableFeatureDetails, context)
            : undefined,
    };
};
var deserializeAws_json1_0BackupDetails = function (output, context) {
    return {
        BackupArn: __expectString(output.BackupArn),
        BackupCreationDateTime: output.BackupCreationDateTime !== undefined && output.BackupCreationDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.BackupCreationDateTime)))
            : undefined,
        BackupExpiryDateTime: output.BackupExpiryDateTime !== undefined && output.BackupExpiryDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.BackupExpiryDateTime)))
            : undefined,
        BackupName: __expectString(output.BackupName),
        BackupSizeBytes: __expectLong(output.BackupSizeBytes),
        BackupStatus: __expectString(output.BackupStatus),
        BackupType: __expectString(output.BackupType),
    };
};
var deserializeAws_json1_0BackupInUseException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0BackupNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0BackupSummaries = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0BackupSummary(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0BackupSummary = function (output, context) {
    return {
        BackupArn: __expectString(output.BackupArn),
        BackupCreationDateTime: output.BackupCreationDateTime !== undefined && output.BackupCreationDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.BackupCreationDateTime)))
            : undefined,
        BackupExpiryDateTime: output.BackupExpiryDateTime !== undefined && output.BackupExpiryDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.BackupExpiryDateTime)))
            : undefined,
        BackupName: __expectString(output.BackupName),
        BackupSizeBytes: __expectLong(output.BackupSizeBytes),
        BackupStatus: __expectString(output.BackupStatus),
        BackupType: __expectString(output.BackupType),
        TableArn: __expectString(output.TableArn),
        TableId: __expectString(output.TableId),
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0BatchExecuteStatementOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        Responses: output.Responses !== undefined && output.Responses !== null
            ? deserializeAws_json1_0PartiQLBatchResponse(output.Responses, context)
            : undefined,
    };
};
var deserializeAws_json1_0BatchGetItemOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        Responses: output.Responses !== undefined && output.Responses !== null
            ? deserializeAws_json1_0BatchGetResponseMap(output.Responses, context)
            : undefined,
        UnprocessedKeys: output.UnprocessedKeys !== undefined && output.UnprocessedKeys !== null
            ? deserializeAws_json1_0BatchGetRequestMap(output.UnprocessedKeys, context)
            : undefined,
    };
};
var deserializeAws_json1_0BatchGetRequestMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0KeysAndAttributes(value, context), _b));
    }, {});
};
var deserializeAws_json1_0BatchGetResponseMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0ItemList(value, context), _b));
    }, {});
};
var deserializeAws_json1_0BatchStatementError = function (output, context) {
    return {
        Code: __expectString(output.Code),
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0BatchStatementResponse = function (output, context) {
    return {
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_json1_0BatchStatementError(output.Error, context)
            : undefined,
        Item: output.Item !== undefined && output.Item !== null
            ? deserializeAws_json1_0AttributeMap(output.Item, context)
            : undefined,
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0BatchWriteItemOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        ItemCollectionMetrics: output.ItemCollectionMetrics !== undefined && output.ItemCollectionMetrics !== null
            ? deserializeAws_json1_0ItemCollectionMetricsPerTable(output.ItemCollectionMetrics, context)
            : undefined,
        UnprocessedItems: output.UnprocessedItems !== undefined && output.UnprocessedItems !== null
            ? deserializeAws_json1_0BatchWriteItemRequestMap(output.UnprocessedItems, context)
            : undefined,
    };
};
var deserializeAws_json1_0BatchWriteItemRequestMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0WriteRequests(value, context), _b));
    }, {});
};
var deserializeAws_json1_0BillingModeSummary = function (output, context) {
    return {
        BillingMode: __expectString(output.BillingMode),
        LastUpdateToPayPerRequestDateTime: output.LastUpdateToPayPerRequestDateTime !== undefined && output.LastUpdateToPayPerRequestDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastUpdateToPayPerRequestDateTime)))
            : undefined,
    };
};
var deserializeAws_json1_0BinarySetAttributeValue = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return context.base64Decoder(entry);
    });
    return retVal;
};
var deserializeAws_json1_0CancellationReason = function (output, context) {
    return {
        Code: __expectString(output.Code),
        Item: output.Item !== undefined && output.Item !== null
            ? deserializeAws_json1_0AttributeMap(output.Item, context)
            : undefined,
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0CancellationReasonList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0CancellationReason(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0Capacity = function (output, context) {
    return {
        CapacityUnits: __limitedParseDouble(output.CapacityUnits),
        ReadCapacityUnits: __limitedParseDouble(output.ReadCapacityUnits),
        WriteCapacityUnits: __limitedParseDouble(output.WriteCapacityUnits),
    };
};
var deserializeAws_json1_0ConditionalCheckFailedException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ConsumedCapacity = function (output, context) {
    return {
        CapacityUnits: __limitedParseDouble(output.CapacityUnits),
        GlobalSecondaryIndexes: output.GlobalSecondaryIndexes !== undefined && output.GlobalSecondaryIndexes !== null
            ? deserializeAws_json1_0SecondaryIndexesCapacityMap(output.GlobalSecondaryIndexes, context)
            : undefined,
        LocalSecondaryIndexes: output.LocalSecondaryIndexes !== undefined && output.LocalSecondaryIndexes !== null
            ? deserializeAws_json1_0SecondaryIndexesCapacityMap(output.LocalSecondaryIndexes, context)
            : undefined,
        ReadCapacityUnits: __limitedParseDouble(output.ReadCapacityUnits),
        Table: output.Table !== undefined && output.Table !== null
            ? deserializeAws_json1_0Capacity(output.Table, context)
            : undefined,
        TableName: __expectString(output.TableName),
        WriteCapacityUnits: __limitedParseDouble(output.WriteCapacityUnits),
    };
};
var deserializeAws_json1_0ConsumedCapacityMultiple = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ConsumedCapacity(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ContinuousBackupsDescription = function (output, context) {
    return {
        ContinuousBackupsStatus: __expectString(output.ContinuousBackupsStatus),
        PointInTimeRecoveryDescription: output.PointInTimeRecoveryDescription !== undefined && output.PointInTimeRecoveryDescription !== null
            ? deserializeAws_json1_0PointInTimeRecoveryDescription(output.PointInTimeRecoveryDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0ContinuousBackupsUnavailableException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ContributorInsightsRuleList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0ContributorInsightsSummaries = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ContributorInsightsSummary(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ContributorInsightsSummary = function (output, context) {
    return {
        ContributorInsightsStatus: __expectString(output.ContributorInsightsStatus),
        IndexName: __expectString(output.IndexName),
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0CreateBackupOutput = function (output, context) {
    return {
        BackupDetails: output.BackupDetails !== undefined && output.BackupDetails !== null
            ? deserializeAws_json1_0BackupDetails(output.BackupDetails, context)
            : undefined,
    };
};
var deserializeAws_json1_0CreateGlobalTableOutput = function (output, context) {
    return {
        GlobalTableDescription: output.GlobalTableDescription !== undefined && output.GlobalTableDescription !== null
            ? deserializeAws_json1_0GlobalTableDescription(output.GlobalTableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0CreateTableOutput = function (output, context) {
    return {
        TableDescription: output.TableDescription !== undefined && output.TableDescription !== null
            ? deserializeAws_json1_0TableDescription(output.TableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DeleteBackupOutput = function (output, context) {
    return {
        BackupDescription: output.BackupDescription !== undefined && output.BackupDescription !== null
            ? deserializeAws_json1_0BackupDescription(output.BackupDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DeleteItemOutput = function (output, context) {
    return {
        Attributes: output.Attributes !== undefined && output.Attributes !== null
            ? deserializeAws_json1_0AttributeMap(output.Attributes, context)
            : undefined,
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        ItemCollectionMetrics: output.ItemCollectionMetrics !== undefined && output.ItemCollectionMetrics !== null
            ? deserializeAws_json1_0ItemCollectionMetrics(output.ItemCollectionMetrics, context)
            : undefined,
    };
};
var deserializeAws_json1_0DeleteRequest = function (output, context) {
    return {
        Key: output.Key !== undefined && output.Key !== null ? deserializeAws_json1_0Key(output.Key, context) : undefined,
    };
};
var deserializeAws_json1_0DeleteTableOutput = function (output, context) {
    return {
        TableDescription: output.TableDescription !== undefined && output.TableDescription !== null
            ? deserializeAws_json1_0TableDescription(output.TableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeBackupOutput = function (output, context) {
    return {
        BackupDescription: output.BackupDescription !== undefined && output.BackupDescription !== null
            ? deserializeAws_json1_0BackupDescription(output.BackupDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeContinuousBackupsOutput = function (output, context) {
    return {
        ContinuousBackupsDescription: output.ContinuousBackupsDescription !== undefined && output.ContinuousBackupsDescription !== null
            ? deserializeAws_json1_0ContinuousBackupsDescription(output.ContinuousBackupsDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeContributorInsightsOutput = function (output, context) {
    return {
        ContributorInsightsRuleList: output.ContributorInsightsRuleList !== undefined && output.ContributorInsightsRuleList !== null
            ? deserializeAws_json1_0ContributorInsightsRuleList(output.ContributorInsightsRuleList, context)
            : undefined,
        ContributorInsightsStatus: __expectString(output.ContributorInsightsStatus),
        FailureException: output.FailureException !== undefined && output.FailureException !== null
            ? deserializeAws_json1_0FailureException(output.FailureException, context)
            : undefined,
        IndexName: __expectString(output.IndexName),
        LastUpdateDateTime: output.LastUpdateDateTime !== undefined && output.LastUpdateDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastUpdateDateTime)))
            : undefined,
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0DescribeEndpointsResponse = function (output, context) {
    return {
        Endpoints: output.Endpoints !== undefined && output.Endpoints !== null
            ? deserializeAws_json1_0Endpoints(output.Endpoints, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeExportOutput = function (output, context) {
    return {
        ExportDescription: output.ExportDescription !== undefined && output.ExportDescription !== null
            ? deserializeAws_json1_0ExportDescription(output.ExportDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeGlobalTableOutput = function (output, context) {
    return {
        GlobalTableDescription: output.GlobalTableDescription !== undefined && output.GlobalTableDescription !== null
            ? deserializeAws_json1_0GlobalTableDescription(output.GlobalTableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeGlobalTableSettingsOutput = function (output, context) {
    return {
        GlobalTableName: __expectString(output.GlobalTableName),
        ReplicaSettings: output.ReplicaSettings !== undefined && output.ReplicaSettings !== null
            ? deserializeAws_json1_0ReplicaSettingsDescriptionList(output.ReplicaSettings, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeKinesisStreamingDestinationOutput = function (output, context) {
    return {
        KinesisDataStreamDestinations: output.KinesisDataStreamDestinations !== undefined && output.KinesisDataStreamDestinations !== null
            ? deserializeAws_json1_0KinesisDataStreamDestinations(output.KinesisDataStreamDestinations, context)
            : undefined,
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0DescribeLimitsOutput = function (output, context) {
    return {
        AccountMaxReadCapacityUnits: __expectLong(output.AccountMaxReadCapacityUnits),
        AccountMaxWriteCapacityUnits: __expectLong(output.AccountMaxWriteCapacityUnits),
        TableMaxReadCapacityUnits: __expectLong(output.TableMaxReadCapacityUnits),
        TableMaxWriteCapacityUnits: __expectLong(output.TableMaxWriteCapacityUnits),
    };
};
var deserializeAws_json1_0DescribeTableOutput = function (output, context) {
    return {
        Table: output.Table !== undefined && output.Table !== null
            ? deserializeAws_json1_0TableDescription(output.Table, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeTableReplicaAutoScalingOutput = function (output, context) {
    return {
        TableAutoScalingDescription: output.TableAutoScalingDescription !== undefined && output.TableAutoScalingDescription !== null
            ? deserializeAws_json1_0TableAutoScalingDescription(output.TableAutoScalingDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DescribeTimeToLiveOutput = function (output, context) {
    return {
        TimeToLiveDescription: output.TimeToLiveDescription !== undefined && output.TimeToLiveDescription !== null
            ? deserializeAws_json1_0TimeToLiveDescription(output.TimeToLiveDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0DuplicateItemException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0Endpoint = function (output, context) {
    return {
        Address: __expectString(output.Address),
        CachePeriodInMinutes: __expectLong(output.CachePeriodInMinutes),
    };
};
var deserializeAws_json1_0Endpoints = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0Endpoint(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ExecuteStatementOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        Items: output.Items !== undefined && output.Items !== null
            ? deserializeAws_json1_0ItemList(output.Items, context)
            : undefined,
        LastEvaluatedKey: output.LastEvaluatedKey !== undefined && output.LastEvaluatedKey !== null
            ? deserializeAws_json1_0Key(output.LastEvaluatedKey, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_0ExecuteTransactionOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        Responses: output.Responses !== undefined && output.Responses !== null
            ? deserializeAws_json1_0ItemResponseList(output.Responses, context)
            : undefined,
    };
};
var deserializeAws_json1_0ExportConflictException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ExportDescription = function (output, context) {
    return {
        BilledSizeBytes: __expectLong(output.BilledSizeBytes),
        ClientToken: __expectString(output.ClientToken),
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        ExportArn: __expectString(output.ExportArn),
        ExportFormat: __expectString(output.ExportFormat),
        ExportManifest: __expectString(output.ExportManifest),
        ExportStatus: __expectString(output.ExportStatus),
        ExportTime: output.ExportTime !== undefined && output.ExportTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExportTime)))
            : undefined,
        FailureCode: __expectString(output.FailureCode),
        FailureMessage: __expectString(output.FailureMessage),
        ItemCount: __expectLong(output.ItemCount),
        S3Bucket: __expectString(output.S3Bucket),
        S3BucketOwner: __expectString(output.S3BucketOwner),
        S3Prefix: __expectString(output.S3Prefix),
        S3SseAlgorithm: __expectString(output.S3SseAlgorithm),
        S3SseKmsKeyId: __expectString(output.S3SseKmsKeyId),
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        TableArn: __expectString(output.TableArn),
        TableId: __expectString(output.TableId),
    };
};
var deserializeAws_json1_0ExportNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ExportSummaries = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ExportSummary(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ExportSummary = function (output, context) {
    return {
        ExportArn: __expectString(output.ExportArn),
        ExportStatus: __expectString(output.ExportStatus),
    };
};
var deserializeAws_json1_0ExportTableToPointInTimeOutput = function (output, context) {
    return {
        ExportDescription: output.ExportDescription !== undefined && output.ExportDescription !== null
            ? deserializeAws_json1_0ExportDescription(output.ExportDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0ExpressionAttributeNameMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_0FailureException = function (output, context) {
    return {
        ExceptionDescription: __expectString(output.ExceptionDescription),
        ExceptionName: __expectString(output.ExceptionName),
    };
};
var deserializeAws_json1_0GetItemOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        Item: output.Item !== undefined && output.Item !== null
            ? deserializeAws_json1_0AttributeMap(output.Item, context)
            : undefined,
    };
};
var deserializeAws_json1_0GlobalSecondaryIndexDescription = function (output, context) {
    return {
        Backfilling: __expectBoolean(output.Backfilling),
        IndexArn: __expectString(output.IndexArn),
        IndexName: __expectString(output.IndexName),
        IndexSizeBytes: __expectLong(output.IndexSizeBytes),
        IndexStatus: __expectString(output.IndexStatus),
        ItemCount: __expectLong(output.ItemCount),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        Projection: output.Projection !== undefined && output.Projection !== null
            ? deserializeAws_json1_0Projection(output.Projection, context)
            : undefined,
        ProvisionedThroughput: output.ProvisionedThroughput !== undefined && output.ProvisionedThroughput !== null
            ? deserializeAws_json1_0ProvisionedThroughputDescription(output.ProvisionedThroughput, context)
            : undefined,
    };
};
var deserializeAws_json1_0GlobalSecondaryIndexDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0GlobalSecondaryIndexDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0GlobalSecondaryIndexes = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0GlobalSecondaryIndexInfo(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0GlobalSecondaryIndexInfo = function (output, context) {
    return {
        IndexName: __expectString(output.IndexName),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        Projection: output.Projection !== undefined && output.Projection !== null
            ? deserializeAws_json1_0Projection(output.Projection, context)
            : undefined,
        ProvisionedThroughput: output.ProvisionedThroughput !== undefined && output.ProvisionedThroughput !== null
            ? deserializeAws_json1_0ProvisionedThroughput(output.ProvisionedThroughput, context)
            : undefined,
    };
};
var deserializeAws_json1_0GlobalTable = function (output, context) {
    return {
        GlobalTableName: __expectString(output.GlobalTableName),
        ReplicationGroup: output.ReplicationGroup !== undefined && output.ReplicationGroup !== null
            ? deserializeAws_json1_0ReplicaList(output.ReplicationGroup, context)
            : undefined,
    };
};
var deserializeAws_json1_0GlobalTableAlreadyExistsException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0GlobalTableDescription = function (output, context) {
    return {
        CreationDateTime: output.CreationDateTime !== undefined && output.CreationDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreationDateTime)))
            : undefined,
        GlobalTableArn: __expectString(output.GlobalTableArn),
        GlobalTableName: __expectString(output.GlobalTableName),
        GlobalTableStatus: __expectString(output.GlobalTableStatus),
        ReplicationGroup: output.ReplicationGroup !== undefined && output.ReplicationGroup !== null
            ? deserializeAws_json1_0ReplicaDescriptionList(output.ReplicationGroup, context)
            : undefined,
    };
};
var deserializeAws_json1_0GlobalTableList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0GlobalTable(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0GlobalTableNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0IdempotentParameterMismatchException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0IndexNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0InternalServerError = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0InvalidEndpointException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0InvalidExportTimeException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0InvalidRestoreTimeException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ItemCollectionKeyAttributeMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0AttributeValue(__expectUnion(value), context), _b));
    }, {});
};
var deserializeAws_json1_0ItemCollectionMetrics = function (output, context) {
    return {
        ItemCollectionKey: output.ItemCollectionKey !== undefined && output.ItemCollectionKey !== null
            ? deserializeAws_json1_0ItemCollectionKeyAttributeMap(output.ItemCollectionKey, context)
            : undefined,
        SizeEstimateRangeGB: output.SizeEstimateRangeGB !== undefined && output.SizeEstimateRangeGB !== null
            ? deserializeAws_json1_0ItemCollectionSizeEstimateRange(output.SizeEstimateRangeGB, context)
            : undefined,
    };
};
var deserializeAws_json1_0ItemCollectionMetricsMultiple = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ItemCollectionMetrics(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ItemCollectionMetricsPerTable = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0ItemCollectionMetricsMultiple(value, context), _b));
    }, {});
};
var deserializeAws_json1_0ItemCollectionSizeEstimateRange = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __limitedParseDouble(entry);
    });
    return retVal;
};
var deserializeAws_json1_0ItemCollectionSizeLimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ItemList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0AttributeMap(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ItemResponse = function (output, context) {
    return {
        Item: output.Item !== undefined && output.Item !== null
            ? deserializeAws_json1_0AttributeMap(output.Item, context)
            : undefined,
    };
};
var deserializeAws_json1_0ItemResponseList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ItemResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0Key = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0AttributeValue(__expectUnion(value), context), _b));
    }, {});
};
var deserializeAws_json1_0KeyList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0Key(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0KeysAndAttributes = function (output, context) {
    return {
        AttributesToGet: output.AttributesToGet !== undefined && output.AttributesToGet !== null
            ? deserializeAws_json1_0AttributeNameList(output.AttributesToGet, context)
            : undefined,
        ConsistentRead: __expectBoolean(output.ConsistentRead),
        ExpressionAttributeNames: output.ExpressionAttributeNames !== undefined && output.ExpressionAttributeNames !== null
            ? deserializeAws_json1_0ExpressionAttributeNameMap(output.ExpressionAttributeNames, context)
            : undefined,
        Keys: output.Keys !== undefined && output.Keys !== null
            ? deserializeAws_json1_0KeyList(output.Keys, context)
            : undefined,
        ProjectionExpression: __expectString(output.ProjectionExpression),
    };
};
var deserializeAws_json1_0KeySchema = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0KeySchemaElement(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0KeySchemaElement = function (output, context) {
    return {
        AttributeName: __expectString(output.AttributeName),
        KeyType: __expectString(output.KeyType),
    };
};
var deserializeAws_json1_0KinesisDataStreamDestination = function (output, context) {
    return {
        DestinationStatus: __expectString(output.DestinationStatus),
        DestinationStatusDescription: __expectString(output.DestinationStatusDescription),
        StreamArn: __expectString(output.StreamArn),
    };
};
var deserializeAws_json1_0KinesisDataStreamDestinations = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0KinesisDataStreamDestination(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0KinesisStreamingDestinationOutput = function (output, context) {
    return {
        DestinationStatus: __expectString(output.DestinationStatus),
        StreamArn: __expectString(output.StreamArn),
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0LimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ListAttributeValue = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0AttributeValue(__expectUnion(entry), context);
    });
    return retVal;
};
var deserializeAws_json1_0ListBackupsOutput = function (output, context) {
    return {
        BackupSummaries: output.BackupSummaries !== undefined && output.BackupSummaries !== null
            ? deserializeAws_json1_0BackupSummaries(output.BackupSummaries, context)
            : undefined,
        LastEvaluatedBackupArn: __expectString(output.LastEvaluatedBackupArn),
    };
};
var deserializeAws_json1_0ListContributorInsightsOutput = function (output, context) {
    return {
        ContributorInsightsSummaries: output.ContributorInsightsSummaries !== undefined && output.ContributorInsightsSummaries !== null
            ? deserializeAws_json1_0ContributorInsightsSummaries(output.ContributorInsightsSummaries, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_0ListExportsOutput = function (output, context) {
    return {
        ExportSummaries: output.ExportSummaries !== undefined && output.ExportSummaries !== null
            ? deserializeAws_json1_0ExportSummaries(output.ExportSummaries, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_0ListGlobalTablesOutput = function (output, context) {
    return {
        GlobalTables: output.GlobalTables !== undefined && output.GlobalTables !== null
            ? deserializeAws_json1_0GlobalTableList(output.GlobalTables, context)
            : undefined,
        LastEvaluatedGlobalTableName: __expectString(output.LastEvaluatedGlobalTableName),
    };
};
var deserializeAws_json1_0ListTablesOutput = function (output, context) {
    return {
        LastEvaluatedTableName: __expectString(output.LastEvaluatedTableName),
        TableNames: output.TableNames !== undefined && output.TableNames !== null
            ? deserializeAws_json1_0TableNameList(output.TableNames, context)
            : undefined,
    };
};
var deserializeAws_json1_0ListTagsOfResourceOutput = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Tags: output.Tags !== undefined && output.Tags !== null
            ? deserializeAws_json1_0TagList(output.Tags, context)
            : undefined,
    };
};
var deserializeAws_json1_0LocalSecondaryIndexDescription = function (output, context) {
    return {
        IndexArn: __expectString(output.IndexArn),
        IndexName: __expectString(output.IndexName),
        IndexSizeBytes: __expectLong(output.IndexSizeBytes),
        ItemCount: __expectLong(output.ItemCount),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        Projection: output.Projection !== undefined && output.Projection !== null
            ? deserializeAws_json1_0Projection(output.Projection, context)
            : undefined,
    };
};
var deserializeAws_json1_0LocalSecondaryIndexDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0LocalSecondaryIndexDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0LocalSecondaryIndexes = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0LocalSecondaryIndexInfo(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0LocalSecondaryIndexInfo = function (output, context) {
    return {
        IndexName: __expectString(output.IndexName),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        Projection: output.Projection !== undefined && output.Projection !== null
            ? deserializeAws_json1_0Projection(output.Projection, context)
            : undefined,
    };
};
var deserializeAws_json1_0MapAttributeValue = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0AttributeValue(__expectUnion(value), context), _b));
    }, {});
};
var deserializeAws_json1_0NonKeyAttributeNameList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0NumberSetAttributeValue = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0PartiQLBatchResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0BatchStatementResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0PointInTimeRecoveryDescription = function (output, context) {
    return {
        EarliestRestorableDateTime: output.EarliestRestorableDateTime !== undefined && output.EarliestRestorableDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EarliestRestorableDateTime)))
            : undefined,
        LatestRestorableDateTime: output.LatestRestorableDateTime !== undefined && output.LatestRestorableDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LatestRestorableDateTime)))
            : undefined,
        PointInTimeRecoveryStatus: __expectString(output.PointInTimeRecoveryStatus),
    };
};
var deserializeAws_json1_0PointInTimeRecoveryUnavailableException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0Projection = function (output, context) {
    return {
        NonKeyAttributes: output.NonKeyAttributes !== undefined && output.NonKeyAttributes !== null
            ? deserializeAws_json1_0NonKeyAttributeNameList(output.NonKeyAttributes, context)
            : undefined,
        ProjectionType: __expectString(output.ProjectionType),
    };
};
var deserializeAws_json1_0ProvisionedThroughput = function (output, context) {
    return {
        ReadCapacityUnits: __expectLong(output.ReadCapacityUnits),
        WriteCapacityUnits: __expectLong(output.WriteCapacityUnits),
    };
};
var deserializeAws_json1_0ProvisionedThroughputDescription = function (output, context) {
    return {
        LastDecreaseDateTime: output.LastDecreaseDateTime !== undefined && output.LastDecreaseDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastDecreaseDateTime)))
            : undefined,
        LastIncreaseDateTime: output.LastIncreaseDateTime !== undefined && output.LastIncreaseDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastIncreaseDateTime)))
            : undefined,
        NumberOfDecreasesToday: __expectLong(output.NumberOfDecreasesToday),
        ReadCapacityUnits: __expectLong(output.ReadCapacityUnits),
        WriteCapacityUnits: __expectLong(output.WriteCapacityUnits),
    };
};
var deserializeAws_json1_0ProvisionedThroughputExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ProvisionedThroughputOverride = function (output, context) {
    return {
        ReadCapacityUnits: __expectLong(output.ReadCapacityUnits),
    };
};
var deserializeAws_json1_0PutItemInputAttributeMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0AttributeValue(__expectUnion(value), context), _b));
    }, {});
};
var deserializeAws_json1_0PutItemOutput = function (output, context) {
    return {
        Attributes: output.Attributes !== undefined && output.Attributes !== null
            ? deserializeAws_json1_0AttributeMap(output.Attributes, context)
            : undefined,
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        ItemCollectionMetrics: output.ItemCollectionMetrics !== undefined && output.ItemCollectionMetrics !== null
            ? deserializeAws_json1_0ItemCollectionMetrics(output.ItemCollectionMetrics, context)
            : undefined,
    };
};
var deserializeAws_json1_0PutRequest = function (output, context) {
    return {
        Item: output.Item !== undefined && output.Item !== null
            ? deserializeAws_json1_0PutItemInputAttributeMap(output.Item, context)
            : undefined,
    };
};
var deserializeAws_json1_0QueryOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        Count: __expectInt32(output.Count),
        Items: output.Items !== undefined && output.Items !== null
            ? deserializeAws_json1_0ItemList(output.Items, context)
            : undefined,
        LastEvaluatedKey: output.LastEvaluatedKey !== undefined && output.LastEvaluatedKey !== null
            ? deserializeAws_json1_0Key(output.LastEvaluatedKey, context)
            : undefined,
        ScannedCount: __expectInt32(output.ScannedCount),
    };
};
var deserializeAws_json1_0Replica = function (output, context) {
    return {
        RegionName: __expectString(output.RegionName),
    };
};
var deserializeAws_json1_0ReplicaAlreadyExistsException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ReplicaAutoScalingDescription = function (output, context) {
    return {
        GlobalSecondaryIndexes: output.GlobalSecondaryIndexes !== undefined && output.GlobalSecondaryIndexes !== null
            ? deserializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingDescriptionList(output.GlobalSecondaryIndexes, context)
            : undefined,
        RegionName: __expectString(output.RegionName),
        ReplicaProvisionedReadCapacityAutoScalingSettings: output.ReplicaProvisionedReadCapacityAutoScalingSettings !== undefined &&
            output.ReplicaProvisionedReadCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ReplicaProvisionedReadCapacityAutoScalingSettings, context)
            : undefined,
        ReplicaProvisionedWriteCapacityAutoScalingSettings: output.ReplicaProvisionedWriteCapacityAutoScalingSettings !== undefined &&
            output.ReplicaProvisionedWriteCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ReplicaProvisionedWriteCapacityAutoScalingSettings, context)
            : undefined,
        ReplicaStatus: __expectString(output.ReplicaStatus),
    };
};
var deserializeAws_json1_0ReplicaAutoScalingDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaAutoScalingDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaDescription = function (output, context) {
    return {
        GlobalSecondaryIndexes: output.GlobalSecondaryIndexes !== undefined && output.GlobalSecondaryIndexes !== null
            ? deserializeAws_json1_0ReplicaGlobalSecondaryIndexDescriptionList(output.GlobalSecondaryIndexes, context)
            : undefined,
        KMSMasterKeyId: __expectString(output.KMSMasterKeyId),
        ProvisionedThroughputOverride: output.ProvisionedThroughputOverride !== undefined && output.ProvisionedThroughputOverride !== null
            ? deserializeAws_json1_0ProvisionedThroughputOverride(output.ProvisionedThroughputOverride, context)
            : undefined,
        RegionName: __expectString(output.RegionName),
        ReplicaInaccessibleDateTime: output.ReplicaInaccessibleDateTime !== undefined && output.ReplicaInaccessibleDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ReplicaInaccessibleDateTime)))
            : undefined,
        ReplicaStatus: __expectString(output.ReplicaStatus),
        ReplicaStatusDescription: __expectString(output.ReplicaStatusDescription),
        ReplicaStatusPercentProgress: __expectString(output.ReplicaStatusPercentProgress),
        ReplicaTableClassSummary: output.ReplicaTableClassSummary !== undefined && output.ReplicaTableClassSummary !== null
            ? deserializeAws_json1_0TableClassSummary(output.ReplicaTableClassSummary, context)
            : undefined,
    };
};
var deserializeAws_json1_0ReplicaDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingDescription = function (output, context) {
    return {
        IndexName: __expectString(output.IndexName),
        IndexStatus: __expectString(output.IndexStatus),
        ProvisionedReadCapacityAutoScalingSettings: output.ProvisionedReadCapacityAutoScalingSettings !== undefined &&
            output.ProvisionedReadCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ProvisionedReadCapacityAutoScalingSettings, context)
            : undefined,
        ProvisionedWriteCapacityAutoScalingSettings: output.ProvisionedWriteCapacityAutoScalingSettings !== undefined &&
            output.ProvisionedWriteCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ProvisionedWriteCapacityAutoScalingSettings, context)
            : undefined,
    };
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaGlobalSecondaryIndexAutoScalingDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexDescription = function (output, context) {
    return {
        IndexName: __expectString(output.IndexName),
        ProvisionedThroughputOverride: output.ProvisionedThroughputOverride !== undefined && output.ProvisionedThroughputOverride !== null
            ? deserializeAws_json1_0ProvisionedThroughputOverride(output.ProvisionedThroughputOverride, context)
            : undefined,
    };
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaGlobalSecondaryIndexDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsDescription = function (output, context) {
    return {
        IndexName: __expectString(output.IndexName),
        IndexStatus: __expectString(output.IndexStatus),
        ProvisionedReadCapacityAutoScalingSettings: output.ProvisionedReadCapacityAutoScalingSettings !== undefined &&
            output.ProvisionedReadCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ProvisionedReadCapacityAutoScalingSettings, context)
            : undefined,
        ProvisionedReadCapacityUnits: __expectLong(output.ProvisionedReadCapacityUnits),
        ProvisionedWriteCapacityAutoScalingSettings: output.ProvisionedWriteCapacityAutoScalingSettings !== undefined &&
            output.ProvisionedWriteCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ProvisionedWriteCapacityAutoScalingSettings, context)
            : undefined,
        ProvisionedWriteCapacityUnits: __expectLong(output.ProvisionedWriteCapacityUnits),
    };
};
var deserializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0Replica(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0ReplicaNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ReplicaSettingsDescription = function (output, context) {
    return {
        RegionName: __expectString(output.RegionName),
        ReplicaBillingModeSummary: output.ReplicaBillingModeSummary !== undefined && output.ReplicaBillingModeSummary !== null
            ? deserializeAws_json1_0BillingModeSummary(output.ReplicaBillingModeSummary, context)
            : undefined,
        ReplicaGlobalSecondaryIndexSettings: output.ReplicaGlobalSecondaryIndexSettings !== undefined && output.ReplicaGlobalSecondaryIndexSettings !== null
            ? deserializeAws_json1_0ReplicaGlobalSecondaryIndexSettingsDescriptionList(output.ReplicaGlobalSecondaryIndexSettings, context)
            : undefined,
        ReplicaProvisionedReadCapacityAutoScalingSettings: output.ReplicaProvisionedReadCapacityAutoScalingSettings !== undefined &&
            output.ReplicaProvisionedReadCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ReplicaProvisionedReadCapacityAutoScalingSettings, context)
            : undefined,
        ReplicaProvisionedReadCapacityUnits: __expectLong(output.ReplicaProvisionedReadCapacityUnits),
        ReplicaProvisionedWriteCapacityAutoScalingSettings: output.ReplicaProvisionedWriteCapacityAutoScalingSettings !== undefined &&
            output.ReplicaProvisionedWriteCapacityAutoScalingSettings !== null
            ? deserializeAws_json1_0AutoScalingSettingsDescription(output.ReplicaProvisionedWriteCapacityAutoScalingSettings, context)
            : undefined,
        ReplicaProvisionedWriteCapacityUnits: __expectLong(output.ReplicaProvisionedWriteCapacityUnits),
        ReplicaStatus: __expectString(output.ReplicaStatus),
        ReplicaTableClassSummary: output.ReplicaTableClassSummary !== undefined && output.ReplicaTableClassSummary !== null
            ? deserializeAws_json1_0TableClassSummary(output.ReplicaTableClassSummary, context)
            : undefined,
    };
};
var deserializeAws_json1_0ReplicaSettingsDescriptionList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0ReplicaSettingsDescription(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0RequestLimitExceeded = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ResourceInUseException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0ResourceNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0RestoreSummary = function (output, context) {
    return {
        RestoreDateTime: output.RestoreDateTime !== undefined && output.RestoreDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.RestoreDateTime)))
            : undefined,
        RestoreInProgress: __expectBoolean(output.RestoreInProgress),
        SourceBackupArn: __expectString(output.SourceBackupArn),
        SourceTableArn: __expectString(output.SourceTableArn),
    };
};
var deserializeAws_json1_0RestoreTableFromBackupOutput = function (output, context) {
    return {
        TableDescription: output.TableDescription !== undefined && output.TableDescription !== null
            ? deserializeAws_json1_0TableDescription(output.TableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0RestoreTableToPointInTimeOutput = function (output, context) {
    return {
        TableDescription: output.TableDescription !== undefined && output.TableDescription !== null
            ? deserializeAws_json1_0TableDescription(output.TableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0ScanOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        Count: __expectInt32(output.Count),
        Items: output.Items !== undefined && output.Items !== null
            ? deserializeAws_json1_0ItemList(output.Items, context)
            : undefined,
        LastEvaluatedKey: output.LastEvaluatedKey !== undefined && output.LastEvaluatedKey !== null
            ? deserializeAws_json1_0Key(output.LastEvaluatedKey, context)
            : undefined,
        ScannedCount: __expectInt32(output.ScannedCount),
    };
};
var deserializeAws_json1_0SecondaryIndexesCapacityMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_0Capacity(value, context), _b));
    }, {});
};
var deserializeAws_json1_0SourceTableDetails = function (output, context) {
    return {
        BillingMode: __expectString(output.BillingMode),
        ItemCount: __expectLong(output.ItemCount),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        ProvisionedThroughput: output.ProvisionedThroughput !== undefined && output.ProvisionedThroughput !== null
            ? deserializeAws_json1_0ProvisionedThroughput(output.ProvisionedThroughput, context)
            : undefined,
        TableArn: __expectString(output.TableArn),
        TableCreationDateTime: output.TableCreationDateTime !== undefined && output.TableCreationDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.TableCreationDateTime)))
            : undefined,
        TableId: __expectString(output.TableId),
        TableName: __expectString(output.TableName),
        TableSizeBytes: __expectLong(output.TableSizeBytes),
    };
};
var deserializeAws_json1_0SourceTableFeatureDetails = function (output, context) {
    return {
        GlobalSecondaryIndexes: output.GlobalSecondaryIndexes !== undefined && output.GlobalSecondaryIndexes !== null
            ? deserializeAws_json1_0GlobalSecondaryIndexes(output.GlobalSecondaryIndexes, context)
            : undefined,
        LocalSecondaryIndexes: output.LocalSecondaryIndexes !== undefined && output.LocalSecondaryIndexes !== null
            ? deserializeAws_json1_0LocalSecondaryIndexes(output.LocalSecondaryIndexes, context)
            : undefined,
        SSEDescription: output.SSEDescription !== undefined && output.SSEDescription !== null
            ? deserializeAws_json1_0SSEDescription(output.SSEDescription, context)
            : undefined,
        StreamDescription: output.StreamDescription !== undefined && output.StreamDescription !== null
            ? deserializeAws_json1_0StreamSpecification(output.StreamDescription, context)
            : undefined,
        TimeToLiveDescription: output.TimeToLiveDescription !== undefined && output.TimeToLiveDescription !== null
            ? deserializeAws_json1_0TimeToLiveDescription(output.TimeToLiveDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0SSEDescription = function (output, context) {
    return {
        InaccessibleEncryptionDateTime: output.InaccessibleEncryptionDateTime !== undefined && output.InaccessibleEncryptionDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.InaccessibleEncryptionDateTime)))
            : undefined,
        KMSMasterKeyArn: __expectString(output.KMSMasterKeyArn),
        SSEType: __expectString(output.SSEType),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_0StreamSpecification = function (output, context) {
    return {
        StreamEnabled: __expectBoolean(output.StreamEnabled),
        StreamViewType: __expectString(output.StreamViewType),
    };
};
var deserializeAws_json1_0StringSetAttributeValue = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0TableAlreadyExistsException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0TableAutoScalingDescription = function (output, context) {
    return {
        Replicas: output.Replicas !== undefined && output.Replicas !== null
            ? deserializeAws_json1_0ReplicaAutoScalingDescriptionList(output.Replicas, context)
            : undefined,
        TableName: __expectString(output.TableName),
        TableStatus: __expectString(output.TableStatus),
    };
};
var deserializeAws_json1_0TableClassSummary = function (output, context) {
    return {
        LastUpdateDateTime: output.LastUpdateDateTime !== undefined && output.LastUpdateDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastUpdateDateTime)))
            : undefined,
        TableClass: __expectString(output.TableClass),
    };
};
var deserializeAws_json1_0TableDescription = function (output, context) {
    return {
        ArchivalSummary: output.ArchivalSummary !== undefined && output.ArchivalSummary !== null
            ? deserializeAws_json1_0ArchivalSummary(output.ArchivalSummary, context)
            : undefined,
        AttributeDefinitions: output.AttributeDefinitions !== undefined && output.AttributeDefinitions !== null
            ? deserializeAws_json1_0AttributeDefinitions(output.AttributeDefinitions, context)
            : undefined,
        BillingModeSummary: output.BillingModeSummary !== undefined && output.BillingModeSummary !== null
            ? deserializeAws_json1_0BillingModeSummary(output.BillingModeSummary, context)
            : undefined,
        CreationDateTime: output.CreationDateTime !== undefined && output.CreationDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreationDateTime)))
            : undefined,
        GlobalSecondaryIndexes: output.GlobalSecondaryIndexes !== undefined && output.GlobalSecondaryIndexes !== null
            ? deserializeAws_json1_0GlobalSecondaryIndexDescriptionList(output.GlobalSecondaryIndexes, context)
            : undefined,
        GlobalTableVersion: __expectString(output.GlobalTableVersion),
        ItemCount: __expectLong(output.ItemCount),
        KeySchema: output.KeySchema !== undefined && output.KeySchema !== null
            ? deserializeAws_json1_0KeySchema(output.KeySchema, context)
            : undefined,
        LatestStreamArn: __expectString(output.LatestStreamArn),
        LatestStreamLabel: __expectString(output.LatestStreamLabel),
        LocalSecondaryIndexes: output.LocalSecondaryIndexes !== undefined && output.LocalSecondaryIndexes !== null
            ? deserializeAws_json1_0LocalSecondaryIndexDescriptionList(output.LocalSecondaryIndexes, context)
            : undefined,
        ProvisionedThroughput: output.ProvisionedThroughput !== undefined && output.ProvisionedThroughput !== null
            ? deserializeAws_json1_0ProvisionedThroughputDescription(output.ProvisionedThroughput, context)
            : undefined,
        Replicas: output.Replicas !== undefined && output.Replicas !== null
            ? deserializeAws_json1_0ReplicaDescriptionList(output.Replicas, context)
            : undefined,
        RestoreSummary: output.RestoreSummary !== undefined && output.RestoreSummary !== null
            ? deserializeAws_json1_0RestoreSummary(output.RestoreSummary, context)
            : undefined,
        SSEDescription: output.SSEDescription !== undefined && output.SSEDescription !== null
            ? deserializeAws_json1_0SSEDescription(output.SSEDescription, context)
            : undefined,
        StreamSpecification: output.StreamSpecification !== undefined && output.StreamSpecification !== null
            ? deserializeAws_json1_0StreamSpecification(output.StreamSpecification, context)
            : undefined,
        TableArn: __expectString(output.TableArn),
        TableClassSummary: output.TableClassSummary !== undefined && output.TableClassSummary !== null
            ? deserializeAws_json1_0TableClassSummary(output.TableClassSummary, context)
            : undefined,
        TableId: __expectString(output.TableId),
        TableName: __expectString(output.TableName),
        TableSizeBytes: __expectLong(output.TableSizeBytes),
        TableStatus: __expectString(output.TableStatus),
    };
};
var deserializeAws_json1_0TableInUseException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0TableNameList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_json1_0TableNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0Tag = function (output, context) {
    return {
        Key: __expectString(output.Key),
        Value: __expectString(output.Value),
    };
};
var deserializeAws_json1_0TagList = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0Tag(entry, context);
    });
    return retVal;
};
var deserializeAws_json1_0TimeToLiveDescription = function (output, context) {
    return {
        AttributeName: __expectString(output.AttributeName),
        TimeToLiveStatus: __expectString(output.TimeToLiveStatus),
    };
};
var deserializeAws_json1_0TimeToLiveSpecification = function (output, context) {
    return {
        AttributeName: __expectString(output.AttributeName),
        Enabled: __expectBoolean(output.Enabled),
    };
};
var deserializeAws_json1_0TransactGetItemsOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        Responses: output.Responses !== undefined && output.Responses !== null
            ? deserializeAws_json1_0ItemResponseList(output.Responses, context)
            : undefined,
    };
};
var deserializeAws_json1_0TransactionCanceledException = function (output, context) {
    return {
        CancellationReasons: output.CancellationReasons !== undefined && output.CancellationReasons !== null
            ? deserializeAws_json1_0CancellationReasonList(output.CancellationReasons, context)
            : undefined,
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0TransactionConflictException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_0TransactionInProgressException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_0TransactWriteItemsOutput = function (output, context) {
    return {
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacityMultiple(output.ConsumedCapacity, context)
            : undefined,
        ItemCollectionMetrics: output.ItemCollectionMetrics !== undefined && output.ItemCollectionMetrics !== null
            ? deserializeAws_json1_0ItemCollectionMetricsPerTable(output.ItemCollectionMetrics, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateContinuousBackupsOutput = function (output, context) {
    return {
        ContinuousBackupsDescription: output.ContinuousBackupsDescription !== undefined && output.ContinuousBackupsDescription !== null
            ? deserializeAws_json1_0ContinuousBackupsDescription(output.ContinuousBackupsDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateContributorInsightsOutput = function (output, context) {
    return {
        ContributorInsightsStatus: __expectString(output.ContributorInsightsStatus),
        IndexName: __expectString(output.IndexName),
        TableName: __expectString(output.TableName),
    };
};
var deserializeAws_json1_0UpdateGlobalTableOutput = function (output, context) {
    return {
        GlobalTableDescription: output.GlobalTableDescription !== undefined && output.GlobalTableDescription !== null
            ? deserializeAws_json1_0GlobalTableDescription(output.GlobalTableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateGlobalTableSettingsOutput = function (output, context) {
    return {
        GlobalTableName: __expectString(output.GlobalTableName),
        ReplicaSettings: output.ReplicaSettings !== undefined && output.ReplicaSettings !== null
            ? deserializeAws_json1_0ReplicaSettingsDescriptionList(output.ReplicaSettings, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateItemOutput = function (output, context) {
    return {
        Attributes: output.Attributes !== undefined && output.Attributes !== null
            ? deserializeAws_json1_0AttributeMap(output.Attributes, context)
            : undefined,
        ConsumedCapacity: output.ConsumedCapacity !== undefined && output.ConsumedCapacity !== null
            ? deserializeAws_json1_0ConsumedCapacity(output.ConsumedCapacity, context)
            : undefined,
        ItemCollectionMetrics: output.ItemCollectionMetrics !== undefined && output.ItemCollectionMetrics !== null
            ? deserializeAws_json1_0ItemCollectionMetrics(output.ItemCollectionMetrics, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateTableOutput = function (output, context) {
    return {
        TableDescription: output.TableDescription !== undefined && output.TableDescription !== null
            ? deserializeAws_json1_0TableDescription(output.TableDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateTableReplicaAutoScalingOutput = function (output, context) {
    return {
        TableAutoScalingDescription: output.TableAutoScalingDescription !== undefined && output.TableAutoScalingDescription !== null
            ? deserializeAws_json1_0TableAutoScalingDescription(output.TableAutoScalingDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_0UpdateTimeToLiveOutput = function (output, context) {
    return {
        TimeToLiveSpecification: output.TimeToLiveSpecification !== undefined && output.TimeToLiveSpecification !== null
            ? deserializeAws_json1_0TimeToLiveSpecification(output.TimeToLiveSpecification, context)
            : undefined,
    };
};
var deserializeAws_json1_0WriteRequest = function (output, context) {
    return {
        DeleteRequest: output.DeleteRequest !== undefined && output.DeleteRequest !== null
            ? deserializeAws_json1_0DeleteRequest(output.DeleteRequest, context)
            : undefined,
        PutRequest: output.PutRequest !== undefined && output.PutRequest !== null
            ? deserializeAws_json1_0PutRequest(output.PutRequest, context)
            : undefined,
    };
};
var deserializeAws_json1_0WriteRequests = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_0WriteRequest(entry, context);
    });
    return retVal;
};
var deserializeMetadata = function (output) {
    var _a;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var buildHttpRpcRequest = function (context, headers, path, resolvedHostname, body) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                contents = {
                    protocol: protocol,
                    hostname: hostname,
                    port: port,
                    method: "POST",
                    path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
                    headers: headers,
                };
                if (resolvedHostname !== undefined) {
                    contents.hostname = resolvedHostname;
                }
                if (body !== undefined) {
                    contents.body = body;
                }
                return [2, new __HttpRequest(contents)];
        }
    });
}); };
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            return JSON.parse(encoded);
        }
        return {};
    });
};
var loadRestJsonErrorCode = function (output, data) {
    var findKey = function (object, key) { return Object.keys(object).find(function (k) { return k.toLowerCase() === key.toLowerCase(); }); };
    var sanitizeErrorCode = function (rawValue) {
        var cleanValue = rawValue;
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    var headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
